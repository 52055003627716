export const VALIDGUESSES = [
'абадан',
'абайла',
'абалан',
'абалат',
'абалау',
'абалақ',
'абақты',
'абдыра',
'абзалы',
'абылай',
'абынып',
'абыржу',
'абыржы',
'абырой',
'абысын',
'абұйыр',
'авария',
'жұрнақ',
'авторы',
'адалау',
'адалда',
'адамды',
'адамсы',
'адамша',
'адамға',
'аданас',
'адарғы',
'адақта',
'адыгей',
'адымда',
'адымды',
'адырай',
'адыраю',
'адырақ',
'адыраң',
'адырлы',
'адырна',
'ажалды',
'ажарла',
'ажарлы',
'ажарын',
'ажуала',
'ажуалы',
'ажырай',
'ажырас',
'ажырат',
'ажырау',
'ажыраю',
'ажырым',
'ажырық',
'ажырғы',
'азабын',
'азагүл',
'азайту',
'азайып',
'азалау',
'азамат',
'азаншы',
'азанғы',
'азапта',
'азапты',
'азарда',
'азарла',
'азатта',
'азатшы',
'аздырт',
'аздығы',
'азотты',
'азсыну',
'азулас',
'азулау',
'азынас',
'азынат',
'азынау',
'азырақ',
'азыңқы',
'азғана',
'азғыру',
'айдала',
'айдалу',
'айдама',
'айдағы',
'айдыны',
'айлала',
'айлалы',
'айлашы',
'аймала',
'айнала',
'айналу',
'айналы',
'айнасы',
'айнаға',
'айныма',
'айтады',
'айтпай',
'айтсам',
'айттат',
'айттыр',
'айттық',
'айтуды',
'айтулы',
'айтушы',
'айтуға',
'айтыла',
'айтылу',
'айтыса',
'айтысу',
'айтысы',
'айтқай',
'айтқан',
'айтқаң',
'айтқыз',
'айтқыш',
'айшаны',
'айшаға',
'айылда',
'айылды',
'айынан',
'айында',
'айынша',
'айының',
'айыпта',
'айыпты',
'айырла',
'айырлы',
'айырма',
'айырыл',
'айырым',
'айырып',
'айырыс',
'айырық',
'айырғы',
'айғағы',
'айқала',
'айқара',
'айқару',
'айқасу',
'айқышы',
'актіле',
'актілі',
'акушер',
'акцент',
'акцепт',
'алааяқ',
'алакөз',
'алалат',
'алалау',
'алалық',
'аламай',
'аламан',
'аламыз',
'аламыш',
'аламық',
'алапай',
'алапат',
'алапес',
'алапты',
'аласар',
'аласат',
'аласта',
'аласыз',
'аласып',
'аласұр',
'алатаң',
'алатын',
'алаула',
'алаура',
'алашақ',
'алашта',
'алаған',
'алағат',
'алағыз',
'алағұс',
'алақай',
'алақан',
'алақта',
'алаңда',
'алаңша',
'алаңқы',
'албаты',
'албыра',
'албырт',
'албыту',
'алдану',
'алдағы',
'алдияр',
'алдына',
'алдыру',
'аллала',
'аллаһы',
'алмады',
'алмала',
'алмалы',
'алмаса',
'алмасу',
'алматы',
'алмауы',
'алмұрт',
'алтайы',
'алудан',
'алудың',
'алулар',
'алуына',
'алхоры',
'алшайт',
'алымды',
'алымыр',
'алынды',
'алынуы',
'алынып',
'алыпты',
'алыста',
'алысып',
'альянс',
'алғаны',
'алғырт',
'алқала',
'алқалы',
'алқынт',
'алқыну',
'амалда',
'аманат',
'аманда',
'амсрия',
'амстау',
'анадай',
'анажан',
'аналар',
'анализ',
'аналық',
'ананас',
'ананың',
'ангина',
'андалы',
'андағы',
'анжыма',
'анилин',
'анкара',
'анкета',
'анодты',
'антала',
'анттас',
'антқор',
'аншлаг',
'анықта',
'апажан',
'апалау',
'апалаң',
'апарам',
'апарыл',
'апарып',
'апарыс',
'апатай',
'апатты',
'апағай',
'аптека',
'аптығу',
'апырау',
'апыраң',
'апырым',
'апырып',
'арабша',
'арайла',
'арайлы',
'аралап',
'аралас',
'аралат',
'аралау',
'аралық',
'арамда',
'арамза',
'аранда',
'арапшы',
'арасан',
'арасат',
'аратпа',
'арбала',
'арбалы',
'арбашы',
'арбаға',
'аренда',
'аржайы',
'арлану',
'арманы',
'арнайы',
'арнала',
'арналы',
'артель',
'артист',
'арттыр',
'артылу',
'артыру',
'артқыз',
'аруана',
'арулау',
'арулық',
'аршулы',
'арызшы',
'арылда',
'арылту',
'арымай',
'арында',
'арынды',
'арықта',
'арықша',
'арқала',
'арқалы',
'арқаға',
'арқыла',
'арқылы',
'арқыра',
'асадал',
'асатын',
'асаусы',
'аспалы',
'аспауы',
'астана',
'астасу',
'астағы',
'астылы',
'астына',
'астырт',
'асусыз',
'асхана',
'асылық',
'асырал',
'асырас',
'асырат',
'асырау',
'асырыл',
'асығыс',
'асықты',
'асқынт',
'асқыну',
'атадан',
'аталас',
'аталуы',
'аталын',
'аталып',
'аталық',
'аталье',
'аталғы',
'атаман',
'атамыз',
'атанша',
'атаның',
'атаулы',
'атаушы',
'атауыз',
'атауыш',
'атауға',
'атаған',
'атақты',
'атаққа',
'атбегі',
'атойла',
'атомды',
'атомшы',
'атпала',
'аттану',
'аттары',
'аттылы',
'атылыс',
'атымен',
'атынан',
'атынды',
'атырап',
'атырау',
'атқарт',
'атқару',
'атқыла',
'ауалан',
'ауанда',
'ауасыз',
'ауданы',
'аудара',
'аударт',
'аудару',
'аузына',
'аумалы',
'аурулы',
'ауруын',
'ауруға',
'ауызба',
'ауызды',
'ауызот',
'ауызша',
'ауызға',
'ауызғы',
'ауылда',
'ауырла',
'ауырту',
'ауырып',
'ауысты',
'ауысуы',
'ауысым',
'ауытта',
'ауытқу',
'ауытқы',
'ауыңқы',
'ауқымы',
'африка',
'ашалау',
'ашалаң',
'ашамай',
'ашатын',
'ашпалы',
'ашудас',
'ашулан',
'ашусыз',
'ашушаң',
'ашуқор',
'ашылды',
'ашылуы',
'ашылып',
'ашылыс',
'ашымал',
'ашымақ',
'ашытпа',
'ашытыл',
'ашытқы',
'ашығыш',
'ашыңқы',
'ащылат',
'ащылау',
'ащылық',
'ащысын',
'аязата',
'аялдат',
'аялдау',
'аямпаз',
'аянкес',
'аяншақ',
'аярлан',
'аярлық',
'аяусыз',
'аяқбау',
'аяқдоп',
'аяқжол',
'аяқсыз',
'аяқсыт',
'аяқтай',
'аяқтал',
'аяқтан',
'аяқтас',
'аяқтат',
'аяқтау',
'аяққап',
'аяңдат',
'аяңдау',
'аяңшыл',
'ағажан',
'ағайын',
'ағалық',
'ағараң',
'ағарту',
'ағасын',
'ағатай',
'ағауыз',
'ағашты',
'ағашшы',
'ағашқа',
'ағуала',
'ағуашы',
'ағызыл',
'ағызып',
'ағында',
'ағынды',
'ағытыл',
'ағытыс',
'ағұрық',
'ағұсақ',
'ақаншы',
'ақарлы',
'ақаулы',
'ақдабы',
'ақжиде',
'ақилан',
'ақирет',
'ақиқат',
'ақмола',
'ақмұрт',
'ақсары',
'ақсиып',
'ақсора',
'ақсыра',
'ақтарт',
'ақтару',
'ақтаяқ',
'ақтиін',
'ақтылы',
'ақтөбе',
'ақшала',
'ақшалы',
'ақшаны',
'ақшасы',
'ақшаға',
'ақшуақ',
'ақшылт',
'ақылды',
'ақылшы',
'ақымақ',
'ақынсу',
'ақынсы',
'ақырай',
'ақырап',
'ақыраю',
'ақырет',
'ақырла',
'ақырын',
'ақырып',
'ақырыс',
'ақырьш',
'ақырғы',
'ақысыз',
'аққала',
'аққұла',
'аңдулы',
'аңдушы',
'аңдуыл',
'аңсыра',
'аңызақ',
'аңызда',
'аңызшы',
'аңызға',
'аңырай',
'аңырат',
'аңырыс',
'аңыста',
'аңғарт',
'аңғару',
'аңғырт',
'аңқайт',
'аңқиып',
'аұшаны',
'бабына',
'бадалу',
'бадана',
'бадиян',
'бажсыз',
'базасы',
'баздан',
'базына',
'байеке',
'байлам',
'байлан',
'байлап',
'байлас',
'байлат',
'байлау',
'байлық',
'байпақ',
'байпаң',
'байрақ',
'байсал',
'байсын',
'байтал',
'байтақ',
'байшыл',
'байыта',
'байыту',
'байғыз',
'байғұс',
'байқал',
'байқап',
'байқас',
'байқат',
'байқау',
'байқұн',
'балала',
'балалы',
'балама',
'баланс',
'балану',
'баланы',
'баласы',
'балаша',
'балаға',
'балбал',
'балгер',
'балдақ',
'балдыз',
'балдыр',
'балдық',
'балиға',
'балпай',
'балпан',
'балпаю',
'балпақ',
'балпаң',
'балпит',
'балпию',
'балпыл',
'балтай',
'балтыр',
'балуан',
'балшық',
'балшөп',
'балғын',
'балқаш',
'балқыт',
'банағы',
'банкет',
'банкир',
'банкке',
'банкін',
'баобаб',
'бапкер',
'баптал',
'баптан',
'баптат',
'баптау',
'бапшыл',
'барады',
'барақы',
'барбаң',
'барбит',
'барбию',
'бардам',
'бардан',
'бардым',
'баржық',
'барлас',
'барлат',
'барлау',
'барлық',
'бармақ',
'барпыл',
'барсын',
'бархан',
'баршын',
'барысы',
'барған',
'барғыз',
'барғыш',
'барқын',
'барқыт',
'басады',
'баспақ',
'бассыз',
'бастал',
'бастан',
'бастап',
'бастас',
'бастат',
'бастау',
'бастақ',
'бастыр',
'бастық',
'басылу',
'басына',
'басыну',
'басқар',
'басқақ',
'басқыз',
'басқын',
'басқыш',
'басқұр',
'баталы',
'батаға',
'батбат',
'батбит',
'батбию',
'батиқа',
'батпан',
'батпақ',
'баттам',
'баттас',
'баттит',
'баттию',
'батырт',
'батыры',
'батқыз',
'баулан',
'баулас',
'баулау',
'баулын',
'баулыс',
'баулық',
'баурай',
'баурап',
'баурау',
'бауыры',
'башлық',
'башмақ',
'башпай',
'баябан',
'баялау',
'баялда',
'баялыш',
'баянда',
'баянды',
'баяула',
'баяусу',
'баяусы',
'бағала',
'бағалы',
'бағамы',
'бағана',
'бағаны',
'бағасы',
'бағбан',
'бағдар',
'бағжаң',
'бағжит',
'бағжию',
'бағлан',
'бағушы',
'бағына',
'бағыну',
'бағыты',
'бақарт',
'бақасу',
'бақаша',
'бақбақ',
'бақоты',
'бақсыз',
'бақтыр',
'бақуат',
'бақшаң',
'бақшит',
'бақшию',
'бақыла',
'бақырт',
'бақыру',
'баққыз',
'беделі',
'бедені',
'бедері',
'бедіре',
'безбен',
'безгег',
'безгек',
'безден',
'бездір',
'безеру',
'безігу',
'безіре',
'бейбас',
'бейбақ',
'бейбіт',
'бейжай',
'бейнет',
'бейнеу',
'бейопа',
'бейпіл',
'бейсал',
'бейтап',
'бейуаз',
'бейуақ',
'бейхош',
'бейқам',
'бейқұт',
'бекзат',
'бектер',
'бектік',
'бекіну',
'бекіре',
'бекіту',
'белбау',
'белбеу',
'белдес',
'белдеу',
'белдік',
'белкин',
'белсен',
'белсіз',
'белтір',
'белуар',
'бензин',
'бергей',
'бергек',
'берген',
'бергіз',
'бергір',
'бергәй',
'бердім',
'береді',
'береке',
'бересі',
'беркін',
'беркіт',
'бермек',
'бермен',
'бермес',
'бермеу',
'берсем',
'берсін',
'бертін',
'беруге',
'беруде',
'беруді',
'беруші',
'беріде',
'беріле',
'берілу',
'бесақа',
'бесене',
'бестен',
'бестеп',
'бестік',
'бесіне',
'бетеге',
'беткей',
'бетпақ',
'бетсіз',
'беттел',
'беттер',
'беттес',
'беттет',
'беттеу',
'беттік',
'беттің',
'бетіне',
'бешпет',
'биебау',
'бизнес',
'бинтте',
'биоток',
'бисіну',
'биттей',
'биттеп',
'биттеу',
'битшең',
'бишара',
'биылша',
'биылғы',
'биялай',
'биікке',
'биікте',
'биіміз',
'биінің',
'боблық',
'бобыра',
'бодыра',
'божбан',
'божбию',
'божыла',
'божыра',
'божышы',
'бозару',
'бозашы',
'бозбас',
'боздау',
'боздақ',
'бозғыл',
'бойдақ',
'бойкот',
'бойлай',
'бойлат',
'бойлау',
'бойлық',
'бойсұн',
'бойшаң',
'бойына',
'боксшы',
'болады',
'болбыр',
'болгар',
'болдыр',
'болдық',
'болжал',
'болжам',
'болжат',
'болжау',
'болжыр',
'болмай',
'болмас',
'болмақ',
'болмыс',
'болпаш',
'болпаң',
'болпию',
'болпық',
'болсақ',
'болсаң',
'болсын',
'болуын',
'болуға',
'болысу',
'болғай',
'болған',
'бомази',
'бопсың',
'борату',
'борбай',
'борбас',
'борбақ',
'борбию',
'бордай',
'бордақ',
'боржит',
'боржию',
'боржық',
'борлат',
'борлау',
'борман',
'борпақ',
'борсаң',
'борсию',
'борсыт',
'борсық',
'бортаң',
'бортит',
'бортию',
'бортық',
'боршын',
'борышы',
'борғұй',
'босану',
'босату',
'босаға',
'бостан',
'бостыр',
'бостық',
'босқан',
'босқын',
'ботала',
'ботана',
'бошала',
'боянып',
'боясыз',
'бояулы',
'бояушы',
'бояған',
'бояғыш',
'боқтас',
'боқтат',
'боқтау',
'боқтық',
'бромда',
'бромды',
'бронда',
'бронды',
'брынза',
'буатта',
'будала',
'будыра',
'буксир',
'булану',
'булығу',
'бумази',
'бумала',
'буржуа',
'буржуй',
'бурлак',
'бусант',
'бусану',
'буылып',
'буында',
'буынды',
'буынып',
'буынға',
'быжына',
'былдыр',
'былжыр',
'былпит',
'былпию',
'былпық',
'былтыр',
'былшит',
'былшию',
'былшыл',
'былшық',
'былығу',
'былған',
'былғап',
'былғас',
'былғат',
'былғау',
'былқыл',
'былқыт',
'бырдай',
'быржит',
'быржию',
'быржық',
'бырсит',
'бырсию',
'быртию',
'быртық',
'быршыт',
'бырысу',
'бырығу',
'быттит',
'быттию',
'бытыра',
'бытықы',
'бытқыл',
'бықсыт',
'бықсық',
'бюджет',
'біздит',
'біздию',
'біздік',
'біздің',
'бізсіз',
'білген',
'білгіз',
'білгір',
'білгіш',
'білдей',
'білдір',
'білдің',
'біледі',
'білеме',
'білмес',
'білуге',
'білуді',
'білуін',
'білімі',
'біліну',
'білісу',
'біразы',
'бірдей',
'бірден',
'біресе',
'біреуі',
'біржан',
'бірлеп',
'бірлес',
'бірлік',
'біртін',
'бірігу',
'біріне',
'біткіз',
'бітпес',
'бітулі',
'бітірт',
'бітіру',
'бітісу',
'бүгжең',
'бүгулі',
'бүгіле',
'бүгілт',
'бүгілу',
'бүйрек',
'бүйтіп',
'бүксіт',
'бүктел',
'бүктес',
'бүктет',
'бүктеу',
'бүктір',
'бүкшең',
'бүкшит',
'бүкшию',
'бүлдір',
'бүлкек',
'бүлкіл',
'бүліну',
'бүрген',
'бүржің',
'бүркек',
'бүркел',
'бүркен',
'бүркет',
'бүркеу',
'бүркіс',
'бүркіт',
'бүрлен',
'бүрлеу',
'бүрлік',
'бүрмек',
'бүрсит',
'бүрсию',
'бүртік',
'бүрулі',
'бүршік',
'бүткіл',
'бұжбан',
'бұзақы',
'бұздыр',
'бұзуды',
'бұзушы',
'бұзылу',
'бұзған',
'бұзғыз',
'бұйрат',
'бұйрық',
'бұйырт',
'бұйыру',
'бұйығу',
'бұйығы',
'бұйқыт',
'бұлала',
'бұлану',
'бұлбұл',
'бұлдан',
'бұлдау',
'бұлдақ',
'бұлдыр',
'бұлдық',
'бұлтар',
'бұлтақ',
'бұлтаң',
'бұлтит',
'бұлтию',
'бұлтты',
'бұлтық',
'бұлтың',
'бұлшық',
'бұлғар',
'бұлғас',
'бұлғау',
'бұлғақ',
'бұлғаң',
'бұлғын',
'бұлғыр',
'бұлқан',
'бұлқын',
'бұлқыс',
'бұлқыт',
'бұндай',
'бұрады',
'бұралт',
'бұралу',
'бұрама',
'бұрауы',
'бұрлық',
'бұрсан',
'бұрташ',
'бұртаң',
'бұртит',
'бұртию',
'бұртық',
'бұршақ',
'бұрылт',
'бұрылу',
'бұрғыз',
'бұрқан',
'бұрқат',
'бұрқау',
'бұрқақ',
'бұрқыл',
'бұталы',
'бұғана',
'бұғышы',
'бұқара',
'бұқаша',
'бұқаға',
'бұқтыр',
'бұққыз',
'бәдеуи',
'бәйпек',
'бәкене',
'бәкізе',
'бәлгер',
'бәлден',
'бәлелі',
'бәлкім',
'бәлсін',
'бәріне',
'бәсеке',
'бәскіл',
'бәстес',
'бәсіре',
'бәңгір',
'бөгелт',
'бөгелу',
'бөгіре',
'бөдене',
'бөкпен',
'бөксер',
'бөктер',
'бөктір',
'бөлген',
'бөлгіз',
'бөлгіш',
'бөлдек',
'бөлдір',
'бөледі',
'бөлтек',
'бөлуге',
'бөлуде',
'бөлуді',
'бөлшек',
'бөлігі',
'бөлімі',
'бөлісу',
'бөрдек',
'бөрене',
'бөрлік',
'бөртек',
'бөртпе',
'бөртін',
'бөрігу',
'бөстік',
'бөстір',
'бөтеге',
'бөтеке',
'вакуум',
'валюта',
'вассал',
'ватман',
'вахтер',
'вектор',
'винтте',
'винтті',
'вокзал',
'вымпел',
'гагара',
'гагауз',
'газдал',
'газдан',
'газдау',
'галифе',
'гамбит',
'гарнир',
'гарпун',
'гектар',
'гендік',
'геолог',
'гепард',
'герман',
'герцог',
'гетман',
'гибрид',
'гильза',
'гипноз',
'гипсте',
'гипсті',
'глобус',
'гормон',
'готика',
'гравер',
'градус',
'грамда',
'гранат',
'гранит',
'гранка',
'график',
'графин',
'графит',
'грекше',
'греция',
'гримде',
'гримші',
'грузин',
'гудрон',
'гүлбас',
'гүлбақ',
'гүлден',
'гүлдет',
'гүлдеу',
'гүлдір',
'гүлзар',
'гүлнар',
'гүлтәж',
'гүлшоқ',
'гүлшік',
'гүмбір',
'гүрбік',
'гүрсіл',
'гүңгір',
'дабыра',
'далаба',
'далаға',
'далбай',
'далбақ',
'далбаң',
'далдың',
'дамбал',
'дамушы',
'дамуын',
'дамуға',
'дамыды',
'дамыту',
'данада',
'данала',
'даналы',
'дананы',
'данасу',
'данасы',
'даниал',
'дарала',
'дарақы',
'дарбит',
'дарбию',
'дарбыз',
'дардай',
'дардаң',
'дардит',
'дардию',
'дариға',
'дарула',
'даруға',
'дарқан',
'дастан',
'даталы',
'даттал',
'даттас',
'даттат',
'даттау',
'датұлы',
'дауала',
'дауалы',
'даугер',
'даукес',
'даулар',
'даурық',
'даусыз',
'даусың',
'даушыл',
'дауына',
'дауысы',
'даярла',
'дағара',
'дағдар',
'дағыра',
'дақтан',
'даңдақ',
'даңғой',
'даңғыл',
'даңғыр',
'даңқты',
'дворян',
'дегбір',
'дегдар',
'дегдіт',
'дегелі',
'дейміз',
'деймін',
'дейсіз',
'дейсің',
'дейтін',
'декрет',
'делбең',
'делдал',
'делдек',
'делдең',
'делдит',
'делебе',
'демалу',
'дембел',
'дембіл',
'демдел',
'демдеу',
'демеуш',
'демсіз',
'демігу',
'дендет',
'дендеу',
'денелі',
'дербес',
'дергей',
'дерлік',
'дерсін',
'дертті',
'десант',
'десерт',
'дефект',
'деңбет',
'деңгей',
'джетті',
'джинсы',
'джордж',
'диабет',
'диалог',
'диамат',
'диатез',
'дизель',
'диктор',
'дилану',
'динамо',
'диплом',
'диспут',
'диуана',
'додала',
'дойман',
'доктор',
'долана',
'долбар',
'долдан',
'долдық',
'доллар',
'долуыр',
'долығу',
'домала',
'домбай',
'домбал',
'домбыт',
'домбық',
'домдау',
'домино',
'дорбит',
'дорбию',
'дордақ',
'досаев',
'досбол',
'достас',
'достық',
'досыңа',
'доцент',
'доғала',
'доғара',
'доғарт',
'доғару',
'доқаба',
'доңғал',
'дренаж',
'дуагер',
'дуагөй',
'дуадақ',
'дуалан',
'дуалат',
'дуалау',
'дуасыз',
'дублер',
'дублет',
'дубляж',
'дудыра',
'дулыға',
'дуылда',
'дыбдыр',
'дымдан',
'дымдау',
'дымығу',
'дымқос',
'дымқыл',
'дырдай',
'дықсыз',
'дықтан',
'дықшыл',
'дыңдай',
'дыңғыр',
'дьякон',
'ділбас',
'ділгер',
'ділмар',
'ділмәр',
'дімкес',
'діндар',
'діндес',
'дінсіз',
'діншіл',
'дірдек',
'діңгек',
'діңгір',
'діңдей',
'діңкию',
'дүбілт',
'дүбілу',
'дүбәра',
'дүлділ',
'дүмбіл',
'дүмбір',
'дүмпіл',
'дүнген',
'дүрбіт',
'дүрдей',
'дүрдек',
'дүрдит',
'дүрдию',
'дүреле',
'дүреші',
'дүркін',
'дүрлік',
'дүрмек',
'дүрмен',
'дүрсіл',
'дүрсін',
'дүңгір',
'дүңкіл',
'дұлбақ',
'дұлбаң',
'дұлдың',
'дұспан',
'дұшпан',
'дұғалы',
'дұғашы',
'дұқаба',
'дұңғыр',
'дәкеле',
'дәлден',
'дәлдеу',
'дәлдит',
'дәлдию',
'дәлдік',
'дәлдір',
'дәлдүң',
'дәлелі',
'дәлшіл',
'дәмден',
'дәмдес',
'дәмдік',
'дәмелі',
'дәмету',
'дәмсіз',
'дәнден',
'дәндік',
'дәнеме',
'дәнеңе',
'дәнсіз',
'дәнігу',
'дәптер',
'дәреже',
'дәркер',
'дәрмек',
'дәрмен',
'дәруіш',
'дәріле',
'дәстүр',
'дәуаяқ',
'дәукес',
'дәулет',
'дәуміт',
'дәурен',
'дәусін',
'дөдеге',
'дөмпей',
'дөрекі',
'дөңбек',
'дөңгей',
'дөңгіл',
'дөңкес',
'дөңкит',
'дөңкию',
'дөңкіл',
'ебедей',
'ебелек',
'европа',
'егделе',
'егелік',
'егемен',
'егерде',
'егерки',
'егерле',
'егерлі',
'егерше',
'егеуле',
'егеулі',
'егеуіш',
'египет',
'егумен',
'егізде',
'егізек',
'егінді',
'егінек',
'егінші',
'егісті',
'еденді',
'едірей',
'едірең',
'едәуір',
'ежегей',
'ежекті',
'ежелгі',
'ежелде',
'ежелен',
'ежелет',
'ежікте',
'ежірей',
'езбеле',
'езгіле',
'езулен',
'езулік',
'езіліп',
'езінді',
'екекеу',
'екенін',
'екесің',
'екеуле',
'екеуің',
'екпелі',
'ексіту',
'екшегі',
'екшеле',
'екіден',
'екілен',
'екілес',
'екілік',
'екінді',
'екінші',
'елбеле',
'елбіре',
'елдері',
'елегіз',
'елегіш',
'електе',
'елекші',
'еленді',
'елесте',
'елесті',
'елеулі',
'елеуре',
'елеуіш',
'елеңде',
'елеңіт',
'елжіре',
'елтірі',
'елубай',
'елулік',
'елікпе',
'елікте',
'елілеу',
'еліміз',
'елінде',
'елінің',
'елірме',
'емделу',
'емеген',
'емексі',
'еменді',
'емеріл',
'емескі',
'емесін',
'еметай',
'емешек',
'емхана',
'емізік',
'емізіл',
'емірде',
'емірен',
'еміріл',
'енгізе',
'енгізу',
'ендеше',
'ендігі',
'ендіру',
'енежан',
'енелес',
'енетін',
'ентеле',
'ентігу',
'еншіле',
'еншісі',
'епелек',
'епеңде',
'ербейт',
'ербиіс',
'ерегіс',
'ережеп',
'ерекше',
'ерелгі',
'ерепан',
'ересей',
'ересек',
'ересен',
'ереулі',
'ереуіл',
'еркейт',
'еркеле',
'еркесу',
'еркесі',
'еркіне',
'ермене',
'ерсілі',
'ерсіре',
'ертегі',
'ертеде',
'ертеле',
'ертелі',
'ерттел',
'ерттес',
'ерттет',
'ерттеу',
'ертөле',
'ерулен',
'ерулет',
'ерулік',
'ерігіш',
'ерікті',
'ерінді',
'ерітпе',
'есалаң',
'есауан',
'есебін',
'есекше',
'еселен',
'еселеп',
'еселет',
'еселеу',
'еселік',
'есепке',
'есепте',
'есепті',
'есепші',
'есеңгі',
'ескере',
'ескерт',
'ескеру',
'ескіле',
'ескілі',
'ескірт',
'ескіру',
'ескіше',
'еспеле',
'еспелі',
'естегі',
'естияр',
'естірт',
'естісе',
'есікке',
'есікпе',
'есікті',
'есікше',
'есікші',
'есімді',
'есімше',
'есімін',
'есінек',
'есінен',
'есінет',
'есінеу',
'есірке',
'есірік',
'есітіл',
'есіңде',
'етегін',
'етекте',
'етекті',
'ететін',
'еткені',
'етпеуі',
'етсіре',
'еттену',
'етудің',
'етхана',
'етікші',
'етілді',
'етісти',
'ешкілі',
'ештеме',
'ештеңе',
'ешімге',
'еңбегі',
'еңкейт',
'еңсейт',
'еңселе',
'еңселі',
'еңсерт',
'еңірен',
'еңірес',
'еңірет',
'еңісте',
'еңісті',
'жабаев',
'жабайы',
'жабағы',
'жаббар',
'жабдан',
'жабдық',
'жабуды',
'жабула',
'жабуға',
'жабыла',
'жабылт',
'жабылу',
'жабысу',
'жадыла',
'жадыра',
'жазады',
'жазала',
'жазалы',
'жазбай',
'жазбау',
'жаздай',
'жаздыр',
'жаздық',
'жазира',
'жазмыш',
'жазуды',
'жазулы',
'жазуша',
'жазушы',
'жазуға',
'жазыла',
'жазылу',
'жазған',
'жазғыз',
'жазғыр',
'жазғыш',
'жайдан',
'жайдақ',
'жайдыр',
'жайлан',
'жайлап',
'жайлас',
'жайлат',
'жайлау',
'жайлық',
'жаймен',
'жайнат',
'жайнау',
'жайнақ',
'жайнаң',
'жайпал',
'жайпас',
'жайпат',
'жайпау',
'жайпақ',
'жайран',
'жайрат',
'жайраң',
'жайсаң',
'жайсыз',
'жайтаң',
'жайуат',
'жайылу',
'жайына',
'жайығу',
'жайғар',
'жайғас',
'жайғат',
'жайғау',
'жайғыз',
'жайқал',
'жайқас',
'жайқау',
'жайқаң',
'жайқын',
'жалама',
'жалант',
'жалану',
'жалату',
'жалақы',
'жалбар',
'жалбас',
'жалбақ',
'жалбаң',
'жалбыз',
'жалбыр',
'жалдан',
'жалдап',
'жалдас',
'жалдат',
'жалдау',
'жалман',
'жалмат',
'жалмау',
'жалмаң',
'жалпай',
'жалпаю',
'жалпақ',
'жалпаң',
'жалпит',
'жалпию',
'жалпыл',
'жалтар',
'жалтақ',
'жалтаң',
'жалтыл',
'жалтыр',
'жалынт',
'жалыну',
'жалығу',
'жалған',
'жалғас',
'жалғат',
'жалғау',
'жалғыз',
'жалқау',
'жалқын',
'жалқық',
'жамбас',
'жамбыл',
'жамиға',
'жамиғи',
'жампаң',
'жампию',
'жампоз',
'жамсат',
'жамсау',
'жамылт',
'жамылу',
'жамыра',
'жамығу',
'жаназа',
'жанама',
'жанасу',
'жандан',
'жандар',
'жандас',
'жандау',
'жандыр',
'жандық',
'жанжал',
'жаннам',
'жаннат',
'жанның',
'жансар',
'жансыз',
'жансын',
'жантай',
'жантақ',
'жануар',
'жануыш',
'жаншыл',
'жаныма',
'жанына',
'жанығу',
'жанған',
'жанғыш',
'жаппай',
'жаппар',
'жапсар',
'жапсыр',
'жаптыр',
'жапыру',
'жапқыз',
'жапқыш',
'жарады',
'жарала',
'жаралу',
'жаралы',
'жарану',
'жарасы',
'жарбай',
'жарбаң',
'жарбит',
'жарбию',
'жаргон',
'жардай',
'жардыр',
'жарлан',
'жарлау',
'жарлық',
'жармас',
'жармақ',
'жарнақ',
'жартас',
'жарылу',
'жарысу',
'жарысы',
'жарған',
'жарғақ',
'жарғыз',
'жарғыш',
'жарқыл',
'жарқын',
'жасады',
'жасалу',
'жасама',
'жасану',
'жасарт',
'жасару',
'жасаса',
'жасасу',
'жассын',
'жастай',
'жастан',
'жастар',
'жастас',
'жастық',
'жасырт',
'жасыру',
'жасқан',
'жасқау',
'жасөмп',
'жасөсп',
'жатады',
'жатпай',
'жатсын',
'жаттал',
'жаттап',
'жаттат',
'жаттау',
'жаттық',
'жатқан',
'жатқыз',
'жауабы',
'жаугер',
'жаудай',
'жаудан',
'жаудыр',
'жаулан',
'жаулап',
'жаулас',
'жаулық',
'жаурат',
'жаусат',
'жаутаң',
'жауына',
'жауыру',
'жауығу',
'жауғыз',
'жауқын',
'жауңар',
'жаядай',
'жаялық',
'жаяула',
'жаяулы',
'жағала',
'жағалы',
'жағасы',
'жағдай',
'жағдан',
'жағулы',
'жағына',
'жағыну',
'жақпар',
'жақсар',
'жақсыз',
'жақтан',
'жақтас',
'жақтау',
'жақтыр',
'жақтық',
'жақтың',
'жақыны',
'жаққыз',
'жаңада',
'жаңала',
'жаңалы',
'жаңарт',
'жаңасы',
'жаңаша',
'жаңағы',
'жаңбыр',
'жаңсақ',
'жаңылт',
'жаңылу',
'жаңғақ',
'жаңғыз',
'жаңғыр',
'жебеле',
'жебелі',
'жегене',
'жегжат',
'жездей',
'жекеле',
'жекеше',
'жеккіз',
'жексен',
'жектір',
'жекірт',
'желаяқ',
'желбей',
'желбең',
'желбір',
'желгіз',
'желгөз',
'желден',
'желдет',
'желдік',
'желдір',
'желкек',
'желкем',
'желкен',
'желкіл',
'желкөз',
'желмая',
'желпең',
'желпіл',
'желпін',
'желсіз',
'желтек',
'желтең',
'желшек',
'желіге',
'желігу',
'желіле',
'желілі',
'желіші',
'желқом',
'желқұз',
'жемдел',
'жемдеу',
'жемдік',
'жемеде',
'жемпаз',
'жемсау',
'жемсіз',
'жемтік',
'жемтір',
'жемшіл',
'жемшөп',
'жемісі',
'жемқор',
'жендет',
'жентек',
'жербас',
'жерден',
'жердің',
'жеребе',
'жеркен',
'жерлен',
'жерлес',
'жерлет',
'жерлеу',
'жерлік',
'жермай',
'жермен',
'жерсіз',
'жерсін',
'жершіл',
'жершөп',
'жетеле',
'жетелі',
'жеткен',
'жеткер',
'жеткіз',
'жетпес',
'жетпіс',
'жеттік',
'жетілт',
'жетілу',
'жетісу',
'жетісі',
'жеңгей',
'жеңгіз',
'жеңдір',
'жеңеді',
'жеңеше',
'жеңсіз',
'жеңсік',
'жеңуші',
'жеңілт',
'жеңілу',
'жеңқап',
'жидашы',
'жиделі',
'жиекте',
'жиекті',
'жиенсі',
'жималы',
'жимпию',
'жиналт',
'жиналу',
'жинама',
'жинағы',
'жинақы',
'жиылып',
'жиылыс',
'жиынды',
'жиынып',
'жиырма',
'жиырыл',
'жиыңқы',
'жиюсыз',
'жиілен',
'жиілет',
'жиілік',
'жобала',
'жобалы',
'жобаны',
'жобасы',
'жобаға',
'жойдыр',
'жойған',
'жойғыз',
'жойқын',
'жолата',
'жолаяқ',
'жолдан',
'жолдар',
'жолдас',
'жолдат',
'жолдау',
'жолдық',
'жолмен',
'жолсыз',
'жолшыл',
'жолына',
'жолығу',
'жолғас',
'жомарт',
'жондан',
'жондат',
'жондыр',
'жонғыз',
'жонғыш',
'жорнал',
'жортақ',
'жортаң',
'жорушы',
'жосала',
'жоспар',
'жосылт',
'жосқын',
'жотала',
'жоталы',
'жоғалт',
'жоғалу',
'жоғары',
'жоғына',
'жоқпыз',
'жоқсын',
'жоқтал',
'жоқтан',
'жоқтас',
'жоқтат',
'жоқтау',
'жоқтық',
'жоңғар',
'жуанда',
'жуанды',
'жуансу',
'жуансы',
'жуасыт',
'жуашық',
'журнал',
'жусату',
'жуынды',
'жуынып',
'жуырда',
'жуықта',
'жылдай',
'жылдам',
'жылдан',
'жылдап',
'жылдар',
'жылдас',
'жылдым',
'жылдық',
'жылдың',
'жылжыт',
'жылмай',
'жылмаң',
'жылмен',
'жылмит',
'жылмию',
'жылмық',
'жылпос',
'жылпыл',
'жылпық',
'жылпың',
'жылтит',
'жылтию',
'жылтыл',
'жылтыр',
'жылтың',
'жылула',
'жылуға',
'жылыла',
'жылына',
'жылынт',
'жымдай',
'жымдас',
'жымиып',
'жымиыс',
'жымпит',
'жымсық',
'жымырт',
'жымыру',
'жымқыр',
'жындан',
'жынысы',
'жынғыл',
'жыпсық',
'жыпырт',
'жырауы',
'жыраша',
'жырбың',
'жырдыр',
'жырлан',
'жырлат',
'жырлау',
'жыртақ',
'жыртит',
'жыртию',
'жыртыл',
'жыртын',
'жыртыс',
'жыртық',
'жырғал',
'жырғау',
'жырғыз',
'жыттыр',
'жытыру',
'жытқыр',
'жығалы',
'жығылу',
'жығылы',
'жықпыл',
'жықтыр',
'жыққыз',
'жыңсыз',
'жыңғыл',
'жіберт',
'жіберу',
'жіктел',
'жіктет',
'жіктеу',
'жіктік',
'жікшіл',
'жінігу',
'жіппен',
'жіпсіз',
'жіпсік',
'жіпсіт',
'жіптік',
'жүгент',
'жүгері',
'жүгірт',
'жүзбен',
'жүзген',
'жүзгіз',
'жүзгіш',
'жүзден',
'жүздеп',
'жүздес',
'жүздік',
'жүздір',
'жүздің',
'жүзеге',
'жүйеге',
'жүйеде',
'жүйеле',
'жүйелі',
'жүйені',
'жүйесі',
'жүйрік',
'жүйткі',
'жүкаяқ',
'жүктел',
'жүктет',
'жүктеу',
'жүндел',
'жүндес',
'жүндеу',
'жүнжіт',
'жүрген',
'жүргіз',
'жүргіш',
'жүрдек',
'жүрдім',
'жүрект',
'жүреле',
'жүрлеу',
'жүрсең',
'жүрсің',
'жұбану',
'жұлбаң',
'жұлбыр',
'жұлдыз',
'жұлдыр',
'жұлтақ',
'жұлтыр',
'жұлыну',
'жұлғыз',
'жұлқар',
'жұлқым',
'жұлқын',
'жұлқыс',
'жұмала',
'жұмбай',
'жұмбақ',
'жұмдыр',
'жұмсай',
'жұмсал',
'жұмсар',
'жұмсау',
'жұмсақ',
'жұмулы',
'жұмылт',
'жұмылу',
'жұмғыз',
'жұнқаш',
'жұптал',
'жұптас',
'жұпыны',
'жұрдай',
'жұртты',
'жұртқа',
'жұрқыл',
'жұтқыз',
'жұтқын',
'жұқала',
'жұқалы',
'жұқана',
'жұқарт',
'жұқару',
'жұқтыр',
'жұққыз',
'жұққыш',
'жәмпей',
'жәмшік',
'жәндік',
'жәрдем',
'жәукем',
'жәутік',
'жөндел',
'жөндем',
'жөндес',
'жөндет',
'жөндеу',
'жөндік',
'жөнелт',
'жөнсіз',
'жөнтек',
'жөнігу',
'жөніне',
'жөргек',
'жөргем',
'жөрмеу',
'жөтелт',
'жөтелу',
'жөткір',
'жөңбай',
'жөңкіл',
'жөңкіт',
'зайығы',
'замана',
'замғау',
'зардап',
'зарлан',
'зарлат',
'зарлау',
'зарлық',
'зарығу',
'заттай',
'заттан',
'заттар',
'заттық',
'заулат',
'заулау',
'зағара',
'заңдар',
'заңгер',
'сазгер',
'ашымық',
'үдеріс',
'әшейін',
'өкілет',
'қиянат',
'заңдас',
'заңдық',
'заңмен',
'заңсыз',
'заңшыл',
'заңына',
'заңғар',
'заңқой',
'звонда',
'зделед',
'зейкеш',
'зейнеп',
'зейнет',
'зекіру',
'зембіл',
'зергер',
'зерлен',
'зерлеу',
'зертте',
'зерігу',
'зеңгір',
'зеңгіт',
'зеңден',
'зеңдік',
'зинашы',
'зиянда',
'зиянды',
'зияпат',
'зиярат',
'значок',
'зоолог',
'зорайт',
'зордан',
'зорлан',
'зорлап',
'зорлау',
'зорлық',
'зорман',
'зорсын',
'зорығу',
'зуылда',
'зымиян',
'зымыра',
'зымғат',
'зымғау',
'зындан',
'зырлат',
'зырлау',
'зырғыт',
'зырқыл',
'зыттию',
'зыттыр',
'зытыру',
'зікіну',
'зілдей',
'зілден',
'зілсіз',
'зіркіл',
'зіңгіт',
'зүрият',
'зұлмат',
'зұлпын',
'зәйтүн',
'зәмзәм',
'зәндем',
'зәрлен',
'зәрсіз',
'зәузат',
'зәулім',
'ибадат',
'ибалық',
'ибасыз',
'иванюк',
'игеріл',
'игеріс',
'игілік',
'игісін',
'идеяны',
'идеясы',
'идірім',
'иектес',
'иектет',
'иектеу',
'иектік',
'иелену',
'иемден',
'изекте',
'изенді',
'изеңде',
'икемде',
'икемді',
'илалла',
'иланым',
'илекте',
'илеулі',
'илеуші',
'иманды',
'имейді',
'имеңде',
'инабат',
'инаныш',
'инедей',
'инекер',
'инелік',
'иректе',
'иректі',
'ирелең',
'иретіл',
'иреңде',
'ирония',
'итаршы',
'итарқа',
'итарқы',
'итауру',
'итақай',
'итекте',
'ителгі',
'итерме',
'итеріл',
'итеріс',
'итеңде',
'итжеме',
'иттену',
'иттесу',
'итшіле',
'итініп',
'ишарат',
'ишатта',
'иықсыз',
'иықтан',
'иықтас',
'иықтат',
'иықтау',
'иілгіш',
'иілдір',
'иіндес',
'иіндет',
'иіндеу',
'иірлік',
'иірмек',
'иіртіс',
'иірулі',
'иіруші',
'иіскет',
'иіскеу',
'иісмай',
'иістен',
'иісшіл',
'кабель',
'кабина',
'кавказ',
'кактус',
'калган',
'калибр',
'калька',
'капрон',
'карбид',
'карбол',
'кариес',
'каркас',
'картон',
'картоп',
'картуз',
'карьер',
'кассир',
'касына',
'каучук',
'кафель',
'кварцы',
'кворум',
'кебеже',
'кебене',
'кеберу',
'кегежу',
'кегжең',
'кедейл',
'кежден',
'кеждік',
'кежеге',
'кезгек',
'кезгіш',
'кездей',
'кездес',
'кездік',
'кездір',
'кезену',
'кезерт',
'кезеру',
'кезеңі',
'кезмал',
'кезнар',
'кезнес',
'кезуіл',
'кезігу',
'кейбір',
'кейеию',
'кейкең',
'кейкит',
'кекете',
'кекету',
'кекжек',
'кекжең',
'кекпір',
'кектен',
'кектес',
'кектеу',
'кекшең',
'кекшит',
'кекшию',
'кекшіл',
'кекіре',
'кекірт',
'кекіру',
'келбет',
'келген',
'келгіз',
'келдек',
'келдім',
'келеді',
'келеке',
'келеле',
'келелі',
'келесі',
'келсап',
'келсоқ',
'келтек',
'келтит',
'келтір',
'келуші',
'келуін',
'келігі',
'келісу',
'кемдеп',
'кемдік',
'кемеші',
'кемиек',
'кемнен',
'кемпес',
'кемпір',
'кемсең',
'кемсию',
'кемсін',
'кемсіт',
'кемтар',
'кемтік',
'кемшіл',
'кемшін',
'кемірт',
'кеміру',
'кеміту',
'кендір',
'кенезе',
'кенелт',
'кенере',
'кепиет',
'кепкіз',
'кепкіш',
'кепсер',
'кепсіт',
'кептел',
'кептер',
'кептес',
'кептет',
'кептеу',
'кептір',
'кепшік',
'кепілі',
'кербез',
'керген',
'кергіш',
'кердең',
'кереге',
'керегі',
'кереле',
'кереші',
'кержік',
'керлен',
'кермал',
'кермек',
'керней',
'кернел',
'кернет',
'кернеу',
'керсен',
'кертеш',
'кертпе',
'кертік',
'кертіл',
'керуен',
'керуіш',
'кершеу',
'керісу',
'кеселе',
'кесене',
'кескек',
'кескіз',
'кескін',
'кескір',
'кескіш',
'кеспек',
'кеспір',
'кестен',
'кестет',
'кестеу',
'кестір',
'кесілу',
'кетеді',
'кеткен',
'кетпен',
'кетуші',
'кетісу',
'кеугім',
'кеудір',
'кеулес',
'кеулет',
'кеулеу',
'кеурек',
'кеусен',
'кеусер',
'кешегі',
'кешелі',
'кешенд',
'кешкен',
'кешкіл',
'кешкір',
'кештет',
'кештік',
'кештір',
'кешігу',
'кешірт',
'кешіру',
'кеңдік',
'кеңейт',
'кеңест',
'кеңесі',
'кеңкей',
'кеңкию',
'кеңкіл',
'кеңіді',
'киеуіл',
'киеңкі',
'килгәй',
'килола',
'килігу',
'кимеле',
'киношы',
'китель',
'киімді',
'клавиш',
'клапан',
'клетка',
'клиент',
'клизма',
'климат',
'книжка',
'кнопка',
'кобура',
'кодекс',
'кодтау',
'кодына',
'кокаин',
'коксте',
'колхаз',
'колхоз',
'комета',
'компас',
'конвой',
'консул',
'коньки',
'король',
'корпус',
'корсет',
'космос',
'костюм',
'котлет',
'кофеин',
'краншы',
'кратер',
'кредит',
'кремль',
'кресло',
'крокет',
'куагер',
'куаныш',
'кубогы',
'кубтел',
'купелі',
'курант',
'курорт',
'курсив',
'куртка',
'курьер',
'куәлік',
'кшелік',
'кібісе',
'кідірт',
'кідіру',
'кіжіну',
'кілбит',
'кілтең',
'кілтте',
'кілтші',
'кілтің',
'кімдік',
'кіндік',
'кінәзу',
'кінәла',
'кінәлы',
'кірбең',
'кірбит',
'кірбию',
'кірбік',
'кірбің',
'кірген',
'кіргіз',
'кірден',
'кіреле',
'кірелі',
'кіресі',
'кіреші',
'кіржит',
'кіржию',
'кіржің',
'кірлен',
'кірлет',
'кірлеу',
'кірпік',
'кірпіш',
'кірсіз',
'кірсін',
'кіртит',
'кіртию',
'кіртік',
'кіршең',
'кіршік',
'кіршіл',
'кірісу',
'кісеге',
'кісіне',
'кісіні',
'кісісі',
'кісіше',
'кітаби',
'кітабы',
'кішілі',
'күбіну',
'күдіре',
'күжбан',
'күждей',
'күздей',
'күздік',
'күземе',
'күзету',
'күйбең',
'күйдір',
'күйдің',
'күйеле',
'күйелі',
'күйеуі',
'күйзел',
'күйзеу',
'күйлен',
'күйлер',
'күйлес',
'күйлеу',
'күйпаз',
'күйрек',
'күйрет',
'күйсіз',
'күйтте',
'күйшіл',
'күйіну',
'күкірт',
'күлгіз',
'күлгін',
'күлгіш',
'күлдей',
'күлден',
'күлдір',
'күледі',
'күлман',
'күлмет',
'күлмең',
'күлтек',
'күлтіл',
'күлшік',
'күлшін',
'күлісу',
'күләбі',
'күмбез',
'күмбір',
'күмпит',
'күмпию',
'күмпіл',
'күнгей',
'күндей',
'күндер',
'күндес',
'күндеу',
'күндіз',
'күндік',
'күнелт',
'күнжіт',
'күннен',
'күннің',
'күнсіз',
'күнсіт',
'күнтай',
'күншіл',
'күніге',
'күніне',
'күнәйі',
'күнәла',
'күнәлы',
'күпсек',
'күпсер',
'күпсін',
'күпсіт',
'күптей',
'күптел',
'күптен',
'күпшек',
'күпілі',
'күпіну',
'күрбік',
'күрбін',
'күрдек',
'күрдел',
'күрдің',
'күресу',
'күресі',
'күржең',
'күржит',
'күржию',
'күржік',
'күркіл',
'күркір',
'күрлет',
'күрлеу',
'күрмек',
'күрмел',
'күрмеу',
'күрсіл',
'күрсін',
'күртік',
'күртіл',
'күршек',
'күршім',
'күстен',
'күтеді',
'күткен',
'күткіз',
'күтсең',
'күттір',
'күтуші',
'күтіну',
'күшала',
'күшейт',
'күшент',
'күшену',
'күшсіз',
'күштен',
'күштеу',
'күшіне',
'күңгір',
'күңдік',
'күңкіл',
'кәбісе',
'кәделе',
'кәделі',
'кәззәп',
'кәйтер',
'кәкима',
'кәкпір',
'кәлима',
'кәмпит',
'кәмшат',
'кәнеки',
'кәнтек',
'кәнігу',
'кәнігі',
'кәрлен',
'кәрлік',
'кәсіби',
'кәсібі',
'кәупек',
'кәусар',
'кәңгір',
'көбдік',
'көбейт',
'көбеле',
'көбіне',
'көгала',
'көгерт',
'көделе',
'көделі',
'көдіре',
'көздей',
'көздел',
'көзден',
'көздер',
'көздес',
'көздет',
'көздеу',
'көзсіз',
'көзігу',
'көйлек',
'көйіту',
'көкала',
'көкбас',
'көкбет',
'көкгүл',
'көкеле',
'көкжал',
'көкзең',
'көкнәр',
'көкпар',
'көкпек',
'көксау',
'көксет',
'көксеу',
'көксіл',
'көктал',
'көктас',
'көктей',
'көктем',
'көктеп',
'көктес',
'көктет',
'көктеу',
'көкшіл',
'көкіме',
'көкқұс',
'көлбар',
'көлбей',
'көлбек',
'көлбет',
'көлбеу',
'көлбең',
'көлгір',
'көлдей',
'көлдет',
'көлдеу',
'көлемі',
'көлкіт',
'көлшік',
'көмгіз',
'көмдір',
'көмегі',
'көмект',
'көмкер',
'көмпек',
'көмпит',
'көмпию',
'көмілт',
'көмілу',
'көнбіс',
'көнгіш',
'көндік',
'көндір',
'көнерт',
'көнеру',
'көнтек',
'көншек',
'көнігу',
'көпене',
'көпжақ',
'көпсін',
'көпсіт',
'көптей',
'көптен',
'көптеп',
'көптес',
'көптеу',
'көптік',
'көптір',
'көпшік',
'көпшіл',
'көпірт',
'көпіру',
'көрбақ',
'көрген',
'көргіз',
'көргіш',
'көрден',
'көрдің',
'көреді',
'көресі',
'көркей',
'көркем',
'көркею',
'көрнек',
'көрнеу',
'көрпеш',
'көрсет',
'көруші',
'көріне',
'көріну',
'көрісу',
'көрқап',
'көсеге',
'көселе',
'көстек',
'көстең',
'көстит',
'көсіле',
'көсілт',
'көсілу',
'көтерт',
'көтеру',
'көшелі',
'көшкін',
'көшпел',
'көшірт',
'көшіру',
'көңілі',
'лавсан',
'лагерь',
'лажсыз',
'лайлан',
'лайлат',
'лайлау',
'лайнер',
'лайсаң',
'лайсыз',
'лакмус',
'лактау',
'лакуна',
'лампас',
'лангет',
'ландыш',
'лансье',
'лантан',
'ланцет',
'лапқой',
'ластан',
'ластық',
'латвия',
'латунь',
'лаулат',
'лаулау',
'лауқұл',
'лағман',
'лақтай',
'лақтат',
'лақтау',
'лақтыр',
'лақтың',
'лаңшыл',
'левкас',
'левкой',
'легион',
'лезгин',
'лездік',
'лейкоз',
'лекало',
'лектор',
'лекция',
'ленбед',
'ленген',
'леріне',
'лимиті',
'линкор',
'лиризм',
'лирика',
'литота',
'литраж',
'литрле',
'лифтер',
'лмеген',
'логика',
'лоджия',
'локаут',
'лосьон',
'лоцман',
'люстра',
'лұқсат',
'ләббай',
'ләззат',
'ләйлек',
'ләйліт',
'ләмбөк',
'ләстік',
'ләшкер',
'магнат',
'магнет',
'магний',
'магнит',
'мадьяр',
'мажыра',
'мазала',
'мазалы',
'маздат',
'маздау',
'маздақ',
'мазлұм',
'мазмұн',
'майдай',
'майдан',
'майкөт',
'майлан',
'майлас',
'майлат',
'майлау',
'майлин',
'майлық',
'маймақ',
'маймаң',
'маймит',
'маймию',
'маймыл',
'майпаз',
'майпақ',
'майсақ',
'майсаң',
'майсыз',
'майсөк',
'майшыл',
'майыру',
'майысу',
'майқан',
'майқор',
'маклер',
'малайя',
'малалы',
'малдай',
'малдан',
'малдас',
'малдыр',
'малжаң',
'малина',
'малсақ',
'малсыз',
'малтау',
'малтыт',
'малтық',
'малшын',
'малынт',
'малыну',
'малғыз',
'малғұн',
'малқам',
'малқор',
'мамала',
'мамалы',
'мамлют',
'мамонт',
'мамыра',
'маналы',
'манарт',
'манағы',
'мандат',
'мандол',
'мандым',
'мандыр',
'мандыт',
'маневр',
'манжет',
'мансап',
'мансұқ',
'мантия',
'мардан',
'мардат',
'мардию',
'мардым',
'мардыт',
'марена',
'маржан',
'маркер',
'маркиз',
'маркса',
'мартен',
'мартық',
'маршал',
'марғау',
'марқай',
'марқау',
'марқаю',
'марқұм',
'масайт',
'масала',
'масасы',
'масаты',
'масаша',
'массаж',
'массив',
'мастан',
'мастау',
'мастек',
'мастер',
'мастық',
'масығу',
'масқыр',
'маталу',
'матасу',
'матату',
'матица',
'матрац',
'матрос',
'матчта',
'матыру',
'маубас',
'маужыр',
'маусым',
'мауыты',
'мауығу',
'махпуз',
'махраб',
'махшар',
'машина',
'маяшық',
'мағзұм',
'мағлұм',
'мағына',
'мақала',
'мақлак',
'мақлұқ',
'мақпал',
'мақпұз',
'мақрұм',
'мақсат',
'мақсым',
'мақсыр',
'мақсұт',
'мақтан',
'мақтат',
'мақтау',
'маңайт',
'маңдай',
'маңдам',
'маңдат',
'маңызы',
'маңыра',
'маңғаз',
'маңқай',
'маңқию',
'мдерге',
'мебель',
'мегдет',
'мегдеу',
'мегзес',
'мегзет',
'мегзеу',
'медаль',
'медиен',
'медици',
'медуза',
'межеле',
'межелі',
'мезгіл',
'мезіле',
'мейман',
'мейрам',
'мекеме',
'мекені',
'мекиен',
'мектеп',
'мекіре',
'мелдек',
'мелжем',
'мелшию',
'мемсан',
'мемуар',
'мендік',
'менмен',
'менсіз',
'менсін',
'ментол',
'меншік',
'меншіл',
'менікі',
'мерген',
'мереке',
'мерзім',
'мертік',
'мертіл',
'мескей',
'местей',
'металл',
'меткел',
'метраж',
'метрле',
'мехнат',
'мешкей',
'меңгер',
'меңдет',
'меңдеу',
'меңзел',
'меңзес',
'меңзеу',
'меңзіт',
'меңсіз',
'микеще',
'микроб',
'микрон',
'миласу',
'мимика',
'мимоза',
'мимырт',
'минала',
'миналы',
'минашы',
'миссия',
'мистер',
'мистик',
'митинг',
'миуала',
'миуалы',
'мифтік',
'михраб',
'миялат',
'миялау',
'мияула',
'миғүла',
'миқата',
'миқұрт',
'модалы',
'модаль',
'модель',
'модуль',
'мойнақ',
'мойшақ',
'мойыма',
'молайт',
'молалы',
'молдық',
'молтақ',
'молығу',
'момдай',
'монарх',
'монизм',
'монтаж',
'монтан',
'монтаң',
'монтер',
'монтит',
'моншақ',
'монғол',
'мораль',
'морена',
'морлан',
'морлат',
'морлау',
'мортық',
'морфий',
'мосқал',
'мрамор',
'муапық',
'музыка',
'мундир',
'мускат',
'мускус',
'муссон',
'муфлон',
'муфтий',
'мшелер',
'мыжылу',
'мыжыма',
'мызғыт',
'мылжың',
'мылтық',
'мылқау',
'мыржию',
'мыржық',
'мырсию',
'мырсыл',
'мыртық',
'мыршым',
'мырғам',
'мысалы',
'мыскер',
'мыстан',
'мысқыл',
'мыштай',
'мықтал',
'мықтан',
'мықтап',
'мықтат',
'мықтау',
'мықшит',
'мықшию',
'мыңдай',
'мыңдап',
'мыңнан',
'мыңғыр',
'мыңғыт',
'мыңқыл',
'мяттық',
'мігітж',
'мінбер',
'мінгес',
'мінгіз',
'мінгіш',
'міндет',
'міндеу',
'міндір',
'мінеки',
'мінсіз',
'міншіл',
'мінісу',
'мінәйі',
'міскін',
'міңгір',
'мүбада',
'мүддел',
'мүдірт',
'мүжілу',
'мүйізі',
'мүксіз',
'мүктел',
'мүктен',
'мүктес',
'мүлгіп',
'мүлгіс',
'мүлгіт',
'мүлтік',
'мүләйт',
'мүмкін',
'мүндир',
'мүрдем',
'мүскін',
'мүшеле',
'мүшелі',
'мүшкіл',
'мүштік',
'мүңкір',
'мүңкіт',
'мүңсік',
'мұжылу',
'мұзарт',
'мұздай',
'мұздан',
'мұздат',
'мұздау',
'мұздақ',
'мұздық',
'мұнара',
'мұнарт',
'мұндай',
'мұндар',
'мұнсыз',
'мұнысы',
'мұрала',
'мұрапа',
'мұрасы',
'мұраға',
'мұрдар',
'мұрсат',
'мұртты',
'мұртша',
'мұхтар',
'мұштар',
'мұғдар',
'мұқаба',
'мұқалт',
'мұқалу',
'мұқату',
'мұқият',
'мұқтаж',
'мұңайт',
'мұңаса',
'мұңдан',
'мұңдас',
'мұңдақ',
'мұңдық',
'мұңлық',
'мұңсыз',
'мұңшыл',
'мұңғыл',
'мәдени',
'мәжбүр',
'мәжнүн',
'мәжуси',
'мәзден',
'мәзсін',
'мәкрүк',
'мәлтек',
'мәміле',
'мәндес',
'мәнері',
'мәнзел',
'мәнзүр',
'мәнсап',
'мәнсіз',
'мәніне',
'мәпеле',
'мәпелі',
'мәрелі',
'мәрмәр',
'мәселе',
'мәстек',
'мәсуек',
'мәсілі',
'мәтіби',
'мәтіні',
'мәуеле',
'мәуелі',
'мәулет',
'мәуліт',
'мәуірт',
'мәшңүр',
'мәшһүр',
'мәңгір',
'мәңгіт',
'мөлдек',
'мөлдір',
'мөлтең',
'мөлтіл',
'мөлтім',
'мөлшер',
'мөрдің',
'мөрлет',
'мөрлеу',
'мөрлік',
'мөрлөк',
'мөрмен',
'мөңкіт',
'мөңіре',
'назалы',
'наздан',
'наздық',
'назшыл',
'назқой',
'найман',
'найсап',
'найқал',
'налалы',
'нанбай',
'нанбас',
'нандыр',
'нандық',
'нансыз',
'нанғыш',
'наразы',
'нардай',
'нарзан',
'нариза',
'наркоз',
'наркөз',
'нарлық',
'нарқын',
'настау',
'настық',
'натрий',
'науаша',
'наубай',
'наупіл',
'наурыз',
'науқан',
'науқас',
'нашашы',
'нағашы',
'нағыра',
'нақтық',
'ндерет',
'неврит',
'невроз',
'негізі',
'нейбет',
'нейлон',
'нейрон',
'некеле',
'некелі',
'нектар',
'немене',
'немере',
'немесе',
'нендей',
'неолит',
'нептун',
'несібе',
'несіне',
'неткен',
'нефрит',
'нешеме',
'никель',
'ниобий',
'нокаут',
'норвег',
'ноғала',
'нттері',
'нысана',
'нысаны',
'нышана',
'нығырт',
'нықтық',
'нэпман',
'нілдей',
'нұрлан',
'нұрсыз',
'нұсқап',
'нұсқау',
'нұқсан',
'нұқыла',
'нәмәрт',
'нәнбай',
'нәндік',
'нәпақа',
'нәркес',
'нәрлен',
'нәрсіз',
'нәтиже',
'нәубет',
'нәумез',
'нәңкір',
'нөмірі',
'обадай',
'обалсу',
'обалсы',
'обатас',
'обашық',
'обаған',
'облысы',
'объект',
'овация',
'одағай',
'одырай',
'одыраю',
'одырақ',
'ожаула',
'ожауша',
'ожырай',
'ожыраю',
'озайын',
'озаңда',
'оздырт',
'озыңқы',
'ойдағы',
'ойдырт',
'ойздық',
'ойлант',
'ойлану',
'ойласу',
'оймала',
'оймалы',
'ойнады',
'ойнақы',
'ойнына',
'ойсыра',
'ойшылы',
'ойылым',
'ойымды',
'ойымен',
'ойында',
'ойынды',
'ойыншы',
'ойының',
'ойынға',
'ойысып',
'ойықша',
'ойыңды',
'ойыңқы',
'ойғызу',
'ойғыла',
'октава',
'олайша',
'оларда',
'оларды',
'оларың',
'оларға',
'олжала',
'олжалы',
'олифте',
'оллаңи',
'олқылы',
'омарта',
'омақас',
'омақат',
'омбыла',
'омбығу',
'омметр',
'омоним',
'омофон',
'омырай',
'омырау',
'омыраю',
'омырыл',
'ондағы',
'онегин',
'оншама',
'онымен',
'оныншы',
'опайке',
'опалан',
'опалат',
'опалау',
'опалаң',
'опасыз',
'оппала',
'оппалы',
'оптика',
'оптығу',
'опыныс',
'опырай',
'опыраю',
'опырыл',
'опырым',
'опырыс',
'опырық',
'оразды',
'орайла',
'орайлы',
'оралым',
'оралып',
'оралғы',
'орамал',
'орамды',
'оранып',
'ораныш',
'орасан',
'оратыл',
'ораулы',
'орауыш',
'орағыт',
'орағыш',
'орақша',
'орақшы',
'ораңда',
'орбита',
'органы',
'ордалы',
'ордасы',
'орнату',
'орнына',
'орнығу',
'ортайт',
'ортала',
'ортаны',
'ортасы',
'орташа',
'ортаға',
'ортеке',
'орында',
'орынды',
'орынша',
'орынға',
'орысша',
'орғыла',
'осалда',
'осетин',
'остырт',
'осыдан',
'осылай',
'осымен',
'осынау',
'осында',
'осынша',
'осының',
'осыған',
'осқыла',
'осқыру',
'отарба',
'отарла',
'отарлы',
'отаршы',
'отаушы',
'отақта',
'отақты',
'отбасы',
'оттары',
'оттегі',
'оттиск',
'оттығу',
'отыншы',
'отының',
'отырар',
'отырды',
'отырса',
'отырып',
'отырыс',
'офицер',
'ошарлы',
'ошарыл',
'ошаған',
'ошақшы',
'ояздан',
'ояздау',
'ояздық',
'оязнай',
'оястау',
'ояттыр',
'оятушы',
'оятқыз',
'ояңдат',
'ояңдау',
'оқалан',
'оқалат',
'оқалау',
'оқалақ',
'оқимыз',
'оқсату',
'оқталу',
'оқтану',
'оқулық',
'оқшима',
'оқырай',
'оқыран',
'оқыраю',
'оқытыл',
'оқытып',
'оқыған',
'оқығыш',
'оңайға',
'оңалту',
'оңдырт',
'оңсыну',
'павиан',
'падашы',
'падиша',
'пайызы',
'пакеті',
'палата',
'палуан',
'палуба',
'пальма',
'пальто',
'памдат',
'панала',
'паналы',
'панама',
'панель',
'папаха',
'папуас',
'парала',
'паркет',
'парлап',
'парник',
'пароль',
'партер',
'партия',
'пассаж',
'пассив',
'патент',
'патиха',
'патрон',
'паштет',
'паңдан',
'паңдық',
'паңсын',
'педаль',
'педант',
'пейзаж',
'пеленг',
'пенжак',
'пенсия',
'пенсне',
'перрон',
'пешене',
'пештеу',
'пиджак',
'пижама',
'пилюля',
'пималы',
'пинцет',
'пионер',
'пистон',
'плазма',
'плакат',
'планер',
'платон',
'плебей',
'пленка',
'пленум',
'пломба',
'плугті',
'погреб',
'подвал',
'поднос',
'помада',
'портты',
'поэзия',
'пресса',
'престе',
'прибор',
'призма',
'примат',
'примус',
'прокат',
'пролог',
'пропан',
'прораб',
'протез',
'протон',
'пряник',
'пуризм',
'пушкин',
'пыстан',
'пыстыр',
'пысына',
'пысқан',
'пысқын',
'пысқыр',
'пышыра',
'пюпитр',
'пілдей',
'піскек',
'піспек',
'пісілі',
'пішуші',
'пұлсыз',
'пұрсат',
'пұттап',
'пұттық',
'пұшпақ',
'пәдері',
'пәлден',
'пәндік',
'пәрмен',
'рабада',
'рабкор',
'рабфак',
'радиал',
'радист',
'радиус',
'размер',
'разрез',
'разряд',
'райком',
'райлан',
'райлас',
'райлау',
'райхан',
'райқан',
'ракета',
'рамала',
'рандау',
'рантье',
'рапира',
'рапорт',
'расизм',
'расист',
'растал',
'растас',
'растат',
'растау',
'растық',
'расчет',
'расшыл',
'рауаят',
'раушан',
'рауғаш',
'рахман',
'рацион',
'рақмет',
'раһман',
'рбикес',
'ревком',
'регент',
'регион',
'реглан',
'реестр',
'резерв',
'резина',
'резюме',
'рейдер',
'рекорд',
'ректор',
'реликт',
'рельеф',
'ремень',
'ремонт',
'ренжіс',
'ренжіт',
'рессор',
'ресурс',
'ретсіз',
'реттел',
'реттен',
'реттеп',
'реттес',
'реттет',
'реттеу',
'реттік',
'ретушь',
'ретуші',
'ретіне',
'рецепт',
'реңден',
'реңдес',
'реңкті',
'реңсіз',
'ризала',
'ритуал',
'риуаят',
'риялан',
'риясыз',
'рияшыл',
'рленед',
'рлеуге',
'рококо',
'рокфор',
'романс',
'романы',
'ромбик',
'рудалы',
'рудник',
'рулары',
'рухани',
'рухтан',
'рухтас',
'руынан',
'рыногы',
'рыцарь',
'рыңғай',
'рюкзак',
'рқатар',
'рүстем',
'рұқсат',
'рәсімі',
'рөлдік',
'сабала',
'сабалы',
'сабылт',
'сабылу',
'садаға',
'садағы',
'садақа',
'садизм',
'садист',
'садыра',
'сазару',
'саздан',
'саздақ',
'сайдақ',
'сайдыр',
'сайлам',
'сайлан',
'сайлап',
'сайлат',
'сайлау',
'сайман',
'сайран',
'сайрат',
'сайрау',
'сайтан',
'сайысу',
'сайғау',
'сайғақ',
'сайғыз',
'сайқал',
'сайқын',
'салады',
'салала',
'салалы',
'саланы',
'саласы',
'салаға',
'салбыр',
'салдан',
'салдар',
'салдау',
'салдыр',
'салдық',
'салмай',
'салмақ',
'салпай',
'салпаю',
'салпақ',
'салпаң',
'салпит',
'салпию',
'салпыл',
'салсаң',
'салтақ',
'салтаң',
'салтты',
'салтын',
'салтқы',
'салуды',
'салушы',
'салуға',
'салығу',
'салығы',
'сальдо',
'салған',
'салғыз',
'салғыр',
'салғыш',
'салқам',
'салқар',
'салқын',
'самала',
'самбыр',
'самиян',
'сампыр',
'самсау',
'самсоз',
'самғат',
'самғау',
'санады',
'саналы',
'сандал',
'сандар',
'сандық',
'санмен',
'сансыз',
'сапада',
'сапалы',
'сапасы',
'сапиян',
'саппас',
'сапсит',
'сапсию',
'сапсыз',
'саптал',
'саптан',
'саптас',
'саптат',
'саптау',
'сапырт',
'сапыру',
'сарала',
'сарана',
'сарбаз',
'сарбас',
'сарбақ',
'сардар',
'саржан',
'саржақ',
'сарлық',
'сарнат',
'сарнау',
'сарнақ',
'сарсаң',
'сарсыл',
'сарсыт',
'сарсық',
'сартап',
'сартыл',
'саруар',
'сарылт',
'сарылу',
'сарығу',
'сарғай',
'сарғаю',
'сарғыш',
'сарқыл',
'сарқын',
'сарқыт',
'саспай',
'састыр',
'сатала',
'сатпақ',
'саттар',
'саттыр',
'саттық',
'сатуды',
'сатулы',
'сатурн',
'сатушы',
'сатуға',
'сатыла',
'сатылы',
'сатқақ',
'сатқыз',
'сатқын',
'саудыр',
'саулат',
'саулау',
'саулық',
'саумал',
'саурық',
'саусау',
'саусақ',
'сауығу',
'сауғыз',
'сауқат',
'сахаба',
'сахара',
'саябан',
'саябақ',
'саябыр',
'саяжай',
'саяжол',
'саязда',
'саялат',
'саялау',
'саясат',
'саятта',
'саятшы',
'саяхат',
'саяқсу',
'саяқсы',
'сағала',
'сағана',
'сағыну',
'сақина',
'сақлап',
'сақпан',
'сақсит',
'сақсию',
'сақсын',
'сақсыр',
'сақтал',
'сақтан',
'сақтап',
'сақтас',
'сақтат',
'сақтау',
'сақтық',
'саңлау',
'саңлақ',
'саңғал',
'саңғыр',
'саңғыт',
'саңқит',
'саңқию',
'саңқыл',
'себебі',
'себеле',
'себуші',
'сезгір',
'сезгіш',
'сездір',
'сезеді',
'сезіну',
'сейдін',
'сейсеп',
'сейілт',
'сейілу',
'секпіл',
'сексен',
'сексию',
'секунд',
'секция',
'секірт',
'секіру',
'селбес',
'селбеу',
'селдет',
'селдір',
'селебе',
'селкем',
'селкеу',
'селкіл',
'селсоқ',
'селтек',
'селтең',
'селтит',
'селтию',
'селқос',
'семдір',
'семпір',
'семсер',
'семірт',
'сенгіз',
'сенгіш',
'сендел',
'сендер',
'сендік',
'сендір',
'сенуші',
'сенікі',
'сепкіз',
'сепкіл',
'сепкіш',
'сепсіт',
'септел',
'септеп',
'септес',
'септеу',
'септік',
'септір',
'сербек',
'сербең',
'сергей',
'сергек',
'сергіп',
'сергіт',
'серейт',
'серкеш',
'сермел',
'сермер',
'сермес',
'сермет',
'сермеу',
'серпіл',
'серпін',
'сертті',
'серуен',
'сескен',
'сеспей',
'сессия',
'сестен',
'сетіне',
'сеңгір',
'сеңсең',
'сигара',
'сигнал',
'символ',
'синтез',
'сипала',
'сипаты',
'сирету',
'сиыршы',
'сиырыл',
'сиырың',
'сиырға',
'сиықты',
'сиякөк',
'сиялан',
'сиялау',
'сияпат',
'сияқты',
'славян',
'слетте',
'слетші',
'снаряд',
'совхоз',
'содана',
'созбай',
'созбақ',
'создыр',
'создық',
'созылу',
'созғыз',
'сойдақ',
'сойдит',
'сойдию',
'сойдыр',
'сойғыз',
'сойқан',
'сократ',
'солбыр',
'солдат',
'солдыр',
'солпит',
'солпию',
'солтақ',
'солшыл',
'солғыз',
'солғын',
'сомада',
'сомала',
'сомалы',
'соманы',
'сомасы',
'сомаға',
'сомдал',
'сомдық',
'сомпаң',
'сонада',
'соналы',
'сондай',
'сондық',
'сонсоң',
'сонсын',
'соныла',
'сопайт',
'сораба',
'сорайт',
'сорақы',
'сордыр',
'сорлан',
'сорлат',
'сорлау',
'сортаң',
'сортта',
'сортты',
'сорығу',
'сорғыз',
'сорғыт',
'сорғыш',
'состию',
'соттал',
'соттас',
'соттат',
'соттау',
'соттың',
'сотқар',
'софист',
'социал',
'соғады',
'соғысу',
'соқала',
'соқалы',
'соқашы',
'соқпай',
'соқпақ',
'соқтыр',
'соқтық',
'соққан',
'соққыз',
'соққын',
'соққыш',
'соңына',
'спектр',
'спикер',
'спринт',
'стажер',
'стайер',
'станок',
'статус',
'статуя',
'стақан',
'стерге',
'стеуде',
'стихия',
'стресс',
'студия',
'суалма',
'суарды',
'суарма',
'суарыл',
'суарым',
'суарыс',
'суауру',
'суағар',
'субеде',
'судыра',
'сулант',
'супияз',
'суреті',
'сусиыр',
'сусыма',
'сусыра',
'сутегі',
'суфлер',
'суылда',
'суырма',
'суырыл',
'суырып',
'суырыс',
'суытыл',
'суытыс',
'суықта',
'суықты',
'суғару',
'съезде',
'сыбант',
'сыбану',
'сыбаға',
'сыбдыр',
'сыдыра',
'сыдырт',
'сыдыру',
'сыздан',
'сыздат',
'сыздау',
'сыздық',
'сызулы',
'сызылт',
'сызылу',
'сызғыз',
'сызғыр',
'сызғыш',
'сыйақы',
'сыйдыр',
'сыйлан',
'сыйлас',
'сыйлат',
'сыйлау',
'сыйлық',
'сыйғыз',
'сылама',
'сылбыр',
'сылдыр',
'сылпық',
'сылпың',
'сылтау',
'сылтық',
'сылтың',
'сылқит',
'сылқию',
'сылқыл',
'сылқым',
'сылқыт',
'сымбал',
'сымбат',
'сымдай',
'сымпит',
'сымпию',
'сымпыл',
'сымпыс',
'сымпың',
'сымсыз',
'сынады',
'сынала',
'сынама',
'сынасу',
'сынауы',
'сындар',
'сындас',
'сындыр',
'сынның',
'сынсыз',
'сынтас',
'сыншыл',
'сыныбы',
'сынған',
'сынғыш',
'сыпайы',
'сыпсың',
'сыптай',
'сыптау',
'сыптию',
'сыпыра',
'сыпырт',
'сыпыру',
'сырашы',
'сырбаз',
'сырдақ',
'сырдаң',
'сырдың',
'сырлан',
'сырлас',
'сырлат',
'сырлау',
'сырмақ',
'сырнай',
'сыртта',
'сыртыл',
'сыртқы',
'сыршыл',
'сырғақ',
'сырғыз',
'сырғыт',
'сырқат',
'сырқау',
'сытылу',
'сыяпат',
'сығала',
'сығысу',
'сықасу',
'сықпан',
'сықпыт',
'сықсит',
'сықсию',
'сықтыр',
'сыңғақ',
'сыңғыр',
'сыңқыл',
'сіздің',
'сілейт',
'сілеле',
'сілкін',
'сілкіп',
'сілкіс',
'сілтен',
'сілтес',
'сілтет',
'сілтеу',
'сімірт',
'сіміру',
'сіресу',
'сіңбір',
'сіңдір',
'сіңірт',
'сіңіру',
'сүбеле',
'сүбелі',
'сүзгіз',
'сүзгіш',
'сүздір',
'сүзекі',
'сүйген',
'сүйгіз',
'сүйгіш',
'сүйдеу',
'сүйдім',
'сүйдір',
'сүйегі',
'сүйене',
'сүйену',
'сүйкел',
'сүйкен',
'сүйкес',
'сүйкет',
'сүйкеу',
'сүйкім',
'сүймен',
'сүйрел',
'сүйрес',
'сүйрет',
'сүйреу',
'сүйрең',
'сүйрік',
'сүйсін',
'сүйтіп',
'сүйінт',
'сүйіну',
'сүйісу',
'сүлдер',
'сүлеле',
'сүмбіл',
'сүмпит',
'сүмпию',
'сүндет',
'сүннет',
'сүннит',
'сүргек',
'сүрген',
'сүргіз',
'сүргін',
'сүргіш',
'сүреле',
'сүрелі',
'сүркіл',
'сүрлем',
'сүрлен',
'сүрлеу',
'сүртті',
'сүртіл',
'сүртін',
'сүртіс',
'сүріну',
'сүтейт',
'сүттей',
'сүттен',
'сүңгек',
'сүңгіт',
'сүңкіл',
'сұбхан',
'сұйылт',
'сұйқыл',
'сұлама',
'сұлату',
'сұлдыр',
'сұлтан',
'сұлула',
'сұлусу',
'сұлусы',
'сұлуша',
'сұмбыр',
'сұмдан',
'сұмдық',
'сұмқай',
'сұрады',
'сұраса',
'сұрасу',
'сұрауы',
'сұрлан',
'сұрлық',
'сұрсыз',
'сұрқай',
'сұрқия',
'сұстан',
'сұстию',
'сұхбат',
'сұғыну',
'сұқсию',
'сұқсыр',
'сұқтал',
'сұқтан',
'сұқтыр',
'сұқтық',
'сұққыз',
'сұңғақ',
'сұңқар',
'сұңқыл',
'сәйгел',
'сәйкес',
'сәлдеу',
'сәмеке',
'сәндел',
'сәнден',
'сәндет',
'сәндеу',
'сәндік',
'сәнсіз',
'сәншіл',
'сәнқой',
'сәресі',
'сәрсен',
'сәтсіз',
'сәттен',
'сәттік',
'сәулем',
'сәулет',
'сәумең',
'сәурік',
'сәңкию',
'сөзбен',
'сөзгер',
'сөзден',
'сөздер',
'сөздік',
'сөздің',
'сөзсіз',
'сөзуар',
'сөзшең',
'сөзіне',
'сөзіңе',
'сөйлем',
'сөйлен',
'сөйлеп',
'сөйлер',
'сөйлес',
'сөйлет',
'сөйлеу',
'сөйтсе',
'сөйтіп',
'сөккіз',
'сөктеу',
'сөктір',
'сөлден',
'сөлпек',
'сөлпию',
'сөмпек',
'сөмпит',
'сөмпию',
'сөндір',
'сөреле',
'табала',
'табашы',
'табель',
'табиғи',
'табуға',
'табынт',
'табыну',
'табысу',
'табысы',
'тазала',
'тазарт',
'тазару',
'тайдыр',
'тайлас',
'тайлау',
'тайлақ',
'тайлық',
'тайпал',
'тайпақ',
'тайпит',
'тайпию',
'тайраң',
'тайсал',
'тайсақ',
'тайтаң',
'тайфун',
'тайыну',
'тайғақ',
'тайғыз',
'тайқар',
'тайқақ',
'талабы',
'талант',
'талану',
'таласа',
'таласу',
'талдан',
'талдау',
'талдыр',
'талмай',
'талмат',
'талмау',
'талмуд',
'талпай',
'талпақ',
'талпию',
'талпын',
'талтай',
'талтаю',
'талтақ',
'талтаң',
'талтип',
'талтит',
'талтию',
'талшын',
'талшық',
'талығу',
'талғам',
'талғап',
'талғау',
'талғақ',
'талқан',
'тамада',
'тамаша',
'тамбур',
'тамдық',
'тампон',
'тампық',
'тамсан',
'тамтам',
'тамтық',
'тамызу',
'тамыры',
'тамған',
'тамғыз',
'тандыр',
'таниды',
'танкер',
'тантық',
'таныды',
'танысу',
'таныту',
'таппай',
'тапсыз',
'тапсын',
'тапсыр',
'таптай',
'таптал',
'таптас',
'таптат',
'таптыр',
'таптық',
'тапшыл',
'тапқан',
'тапқыз',
'тапқыр',
'тапқыш',
'тарабы',
'таразы',
'таралу',
'тарама',
'тарану',
'тарань',
'тарата',
'тарату',
'тарбай',
'тарбақ',
'тарбаң',
'тарбит',
'тарихи',
'тарихы',
'тарлан',
'тарлау',
'тарлық',
'тармас',
'тармақ',
'тарнау',
'тарпан',
'тарпаң',
'тарпыл',
'тарпын',
'тарпыт',
'тарсыл',
'тарсын',
'тартар',
'тартпа',
'тартты',
'тартыл',
'тартым',
'тартын',
'тартып',
'тартыс',
'тартқы',
'тархан',
'тарылт',
'тарылу',
'тарышы',
'тарығу',
'тарғақ',
'тарғыл',
'тарғын',
'тарқал',
'тарқан',
'тарқас',
'тарқат',
'тарқау',
'тасала',
'тасалы',
'таспиқ',
'тастай',
'тастал',
'тастам',
'тастан',
'тастап',
'тастас',
'тастат',
'тастау',
'тастақ',
'тастұз',
'тасушы',
'тасшөп',
'тасына',
'тасқын',
'таттан',
'таттыр',
'татқыз',
'таулық',
'таупық',
'таусыл',
'таутан',
'таушық',
'таязда',
'таяныш',
'таяуда',
'таяқта',
'таяқша',
'тағады',
'тағала',
'тағалы',
'тағашы',
'тағдыр',
'тағзым',
'тақпақ',
'тақсыр',
'тақтай',
'тақтақ',
'тақтыр',
'таққыз',
'таңдай',
'таңдал',
'таңдан',
'таңдап',
'таңдас',
'таңдат',
'таңдау',
'таңдақ',
'таңдыр',
'таңдық',
'таңлай',
'таңлақ',
'таңсық',
'таңғыз',
'таңғыш',
'таңқит',
'таңқию',
'таңқыш',
'театры',
'тегене',
'тегжей',
'тежелт',
'тежелу',
'тездет',
'тездеу',
'тездік',
'тексер',
'тексіз',
'тектен',
'тектес',
'тектұр',
'телекс',
'телмең',
'телмір',
'телпек',
'телуші',
'телшік',
'темекі',
'теннис',
'тентек',
'теолог',
'теория',
'тепсең',
'тепсін',
'тептір',
'тербел',
'тербен',
'тербес',
'тербет',
'тергел',
'терген',
'тергеу',
'тергіз',
'тергіш',
'тергүл',
'терезе',
'терлеп',
'терлет',
'терлеу',
'терлік',
'термин',
'термит',
'термос',
'террор',
'терсек',
'теруші',
'тершең',
'тершіл',
'терісі',
'тескіз',
'тескіш',
'тестір',
'техник',
'теңбіл',
'теңгер',
'теңдей',
'теңдел',
'теңдес',
'теңдет',
'теңдеу',
'теңдік',
'теңелт',
'теңкит',
'теңсіз',
'тигізу',
'тиегіш',
'тиекте',
'тиекті',
'типтік',
'тирада',
'титтей',
'тиянақ',
'тиімді',
'тиінді',
'тиісті',
'тобала',
'тобына',
'тозақы',
'тоздан',
'тоздау',
'тоздыр',
'тозығы',
'тозған',
'тозғақ',
'тозғын',
'тозғыш',
'тойдыр',
'тойдың',
'тойлан',
'тойлап',
'тойлас',
'тойлат',
'тойлау',
'тойлық',
'тоймас',
'тойпаң',
'тойпыл',
'тойтар',
'тойтаң',
'тойтық',
'тойынт',
'тойыну',
'тойғыз',
'токарь',
'толмау',
'толтыр',
'толулы',
'толысу',
'толығу',
'толғай',
'толғам',
'толған',
'толғас',
'толғат',
'толғау',
'толғақ',
'толғыз',
'толқын',
'толқыт',
'томала',
'томаша',
'томаяқ',
'томаға',
'томдау',
'томдық',
'томпай',
'томпаю',
'томпақ',
'томпаң',
'томпит',
'томпию',
'томсар',
'томырт',
'томыру',
'тоналу',
'тонату',
'тондар',
'тондық',
'тоника',
'тоннаж',
'тонсыз',
'топтал',
'топтан',
'топтар',
'топтас',
'топтат',
'топтау',
'топтық',
'топтың',
'топшыл',
'топшық',
'топылу',
'торалт',
'торалу',
'торкөз',
'торлан',
'торлас',
'торлат',
'торлау',
'торлық',
'тормоз',
'торпақ',
'торсай',
'торсақ',
'торсит',
'торсию',
'торсыл',
'торсық',
'тортию',
'торула',
'торуыл',
'торшер',
'торылу',
'торысу',
'торыту',
'торығу',
'торғай',
'торғыл',
'торғын',
'тостақ',
'тостыр',
'тосысу',
'тосқан',
'тосқау',
'тосқыз',
'тосқын',
'тотиту',
'тоттан',
'тотығу',
'тошала',
'тоятта',
'тоғайт',
'тоғарт',
'тоғылт',
'тоғыма',
'тоғысу',
'тоғыту',
'тоқбақ',
'тоқпан',
'тоқпақ',
'тоқсай',
'тоқсан',
'тоқсақ',
'тоқсын',
'тоқсыр',
'тоқтай',
'тоқтал',
'тоқтам',
'тоқтас',
'тоқтат',
'тоқтау',
'тоқтық',
'тоқулы',
'тоқушы',
'тоқыма',
'тоқыра',
'тоңазы',
'тоңдан',
'тоңдат',
'тоңдау',
'тоңдыр',
'тоңғақ',
'тоңғыз',
'тоңғыш',
'тоңқай',
'тоңқаю',
'травма',
'трагик',
'тралда',
'трасса',
'трахея',
'тренаж',
'трепак',
'треска',
'тресте',
'трибун',
'тритон',
'триумф',
'тройка',
'тропик',
'трофей',
'трубка',
'труппа',
'туажат',
'туалет',
'туашақ',
'туаған',
'тудырт',
'тудыру',
'тулату',
'тунгус',
'тундра',
'турала',
'туралу',
'туралы',
'турасу',
'турату',
'туризм',
'турист',
'турник',
'турнир',
'тусыра',
'туудың',
'тууына',
'туында',
'туынды',
'туырыл',
'тыйдыр',
'тыйылт',
'тыйылу',
'тыйғыз',
'тылсым',
'тылтит',
'тылтию',
'тымдап',
'тымпит',
'тымпию',
'тымпың',
'тымсал',
'тымсақ',
'тындыр',
'тыншай',
'тыншыт',
'тыншық',
'тынығу',
'тырайт',
'тырақы',
'тырбан',
'тырбақ',
'тырбаң',
'тырбит',
'тырбию',
'тырбын',
'тырбық',
'тырбың',
'тырдай',
'тыржаң',
'тыржит',
'тыржию',
'тыржың',
'тырлау',
'тырмақ',
'тырмыс',
'тырнас',
'тырнат',
'тырнақ',
'тырсит',
'тырсию',
'тырсыл',
'тыртаң',
'тыртит',
'тыртию',
'тыртыл',
'тыртыс',
'тыртық',
'тыртың',
'тырула',
'тырысу',
'тырқию',
'тырқыл',
'тыссыз',
'тыстал',
'тыстас',
'тыстат',
'тыстау',
'тыстық',
'тыштию',
'тышқан',
'тышқақ',
'тыюсыз',
'тығулы',
'тығылт',
'тығылу',
'тықсыр',
'тықтыр',
'тықырт',
'тыққыз',
'тыңайт',
'тыңдал',
'тыңдап',
'тыңдас',
'тыңдат',
'тыңдау',
'тыңнан',
'тыңғыр',
'тыңқит',
'тыңқию',
'тыңқыл',
'тюлень',
'тігулі',
'тігуші',
'тігілу',
'тізбек',
'тізгіз',
'тізгін',
'тізгіш',
'тіздел',
'тіздет',
'тіздеу',
'тіздір',
'тізекі',
'тізеле',
'тізелі',
'тізулі',
'тізілт',
'тізілу',
'тікейт',
'тікеле',
'тіккіз',
'тіксін',
'тіктен',
'тіктес',
'тіктет',
'тіктеу',
'тіктік',
'тіктір',
'тікшит',
'тікшию',
'тікшіл',
'тілгіз',
'тілгір',
'тілгіш',
'тілдей',
'тілден',
'тілдес',
'тілдет',
'тілдеу',
'тілдік',
'тілдір',
'тілдің',
'тілент',
'тілену',
'тілесе',
'тілкем',
'тілмар',
'тілмаш',
'тілмен',
'тілмер',
'тілсіз',
'тілсім',
'тілуар',
'тілулі',
'тілхат',
'тілшік',
'тіліну',
'тімсін',
'тімтін',
'тінтіл',
'тінтін',
'тінтіс',
'тіптен',
'тіптең',
'тіргіз',
'тірелт',
'тіресу',
'тіркей',
'тіркел',
'тіркем',
'тіркес',
'тіркет',
'тіркеу',
'тіркіс',
'тірлік',
'тірнек',
'тірсек',
'тірілт',
'тірілу',
'тіссіз',
'тістей',
'тістел',
'тістем',
'тістен',
'тістес',
'тістет',
'тістеу',
'тісшөп',
'тітіре',
'түбіңе',
'түгесу',
'түздік',
'түзелт',
'түзелу',
'түзесу',
'түзету',
'түзсіз',
'түзуле',
'түзшіл',
'түзілу',
'түйгіз',
'түйгіш',
'түйдей',
'түйдек',
'түйдір',
'түйеле',
'түйелі',
'түйеше',
'түйеші',
'түйлік',
'түймеш',
'түйміш',
'түйнек',
'түйнел',
'түйрел',
'түйрес',
'түйрет',
'түйреу',
'түйсік',
'түйсін',
'түйткі',
'түйтік',
'түйтің',
'түйілт',
'түйілу',
'түйіну',
'түйіні',
'түйісу',
'түкпір',
'түксит',
'түксию',
'түксіз',
'түксік',
'түктен',
'түктес',
'түктет',
'түктеу',
'түкірт',
'түкіру',
'түлежу',
'түлежі',
'түлеме',
'түлету',
'түндей',
'түндік',
'түнеме',
'түнерт',
'түнсіз',
'түніке',
'түпкір',
'түпсіз',
'түптел',
'түптен',
'түптес',
'түптет',
'түптеу',
'түрады',
'түрген',
'түргіз',
'түргіш',
'түрдір',
'түрдің',
'түреге',
'түркше',
'түркіт',
'түрлен',
'түрлер',
'түрлес',
'түрлет',
'түрпат',
'түрпет',
'түрсіз',
'түрсіл',
'түрткі',
'түртіл',
'түртін',
'түртіп',
'түртіс',
'түрулі',
'түруші',
'түршік',
'түрілт',
'түрілу',
'түріне',
'түріну',
'түседі',
'түскен',
'түскер',
'түскіз',
'түскір',
'түспей',
'түспек',
'түссіз',
'түстел',
'түстен',
'түстес',
'түстет',
'түстеу',
'түстік',
'түсуде',
'түсуді',
'түсуін',
'түсімі',
'түсіну',
'түсірт',
'түсіру',
'түсісу',
'түтеле',
'түтету',
'түткіз',
'түттір',
'түтулі',
'түтуші',
'түтілу',
'түтісу',
'түшкір',
'түңкіл',
'түңлік',
'түңілт',
'түңілу',
'тұжырт',
'тұжыру',
'тұздай',
'тұздал',
'тұздан',
'тұздас',
'тұздат',
'тұздау',
'тұздық',
'тұзсыз',
'тұзған',
'тұйтию',
'тұйғын',
'тұлдан',
'тұлдас',
'тұлдат',
'тұлдау',
'тұлдыр',
'тұлпар',
'тұмалы',
'тұмант',
'тұмдап',
'тұмсар',
'тұмсық',
'тұндыр',
'тұнжыр',
'тұншық',
'тұнғыз',
'тұрады',
'тұрала',
'тұралы',
'тұрлар',
'тұрлат',
'тұрлау',
'тұрмай',
'тұрман',
'тұрмыс',
'тұрпан',
'тұрпат',
'тұрсыл',
'тұруды',
'тұрушы',
'тұрылу',
'тұрысу',
'тұрған',
'тұрғыз',
'тұрғын',
'тұрғыш',
'тұсалу',
'тұсату',
'тұспал',
'тұспан',
'тұстал',
'тұстан',
'тұстас',
'тұсына',
'тұтант',
'тұтану',
'тұтату',
'тұттай',
'тұттыр',
'тұттық',
'тұтулы',
'тұтушы',
'тұтылу',
'тұтыну',
'тұтысу',
'тұтығу',
'тұтқан',
'тұтқыз',
'тұтқын',
'тұтқыр',
'тұтқыш',
'тұшала',
'тұштаң',
'тұштит',
'тұштию',
'тұшынт',
'тұшыну',
'тұшқыл',
'тұщыла',
'тұщылы',
'тұщыту',
'тұяқты',
'тұғжит',
'тұқиту',
'тұқиян',
'тұқшию',
'тұқырт',
'тұқыру',
'тұңғыш',
'тұңғұт',
'тәбділ',
'тәжіке',
'тәйтік',
'тәлкек',
'тәлпіш',
'тәлтек',
'тәлтит',
'тәмпік',
'тәмпіш',
'тәмсіл',
'тәндік',
'тәнтік',
'тәпсір',
'тәптер',
'тәрбие',
'тәрнек',
'тәртіп',
'тәрізі',
'тәсілі',
'тәтеле',
'тәулік',
'тәурат',
'тәштит',
'тәштию',
'төбеле',
'төбелі',
'төгілт',
'төгілу',
'төгісу',
'төзбес',
'төзгіш',
'төздір',
'төзуші',
'төккіз',
'төккіш',
'төкпей',
'төктір',
'төлден',
'төлдет',
'төлену',
'төлету',
'төлеуд',
'төменг',
'төменд',
'төмпек',
'төмпеш',
'төнгіз',
'төндір',
'төнісу',
'төпеле',
'төпету',
'төраға',
'төрдей',
'төреге',
'төреле',
'төрелі',
'төресу',
'төресі',
'төреші',
'төркін',
'төрлет',
'төрлеу',
'төртеу',
'төршіл',
'төселт',
'төселу',
'төсент',
'төсену',
'төсесу',
'төсету',
'төскей',
'төскер',
'төстек',
'төстес',
'төстет',
'төстеу',
'төстит',
'төстию',
'төстік',
'төтеле',
'төңкер',
'уандыр',
'уаттыр',
'уатушы',
'уатқыз',
'уатқыш',
'уақиға',
'уақсын',
'уақтан',
'уақтат',
'уақтау',
'удмурт',
'украин',
'улағыш',
'улылық',
'умажда',
'унитаз',
'уролог',
'усасыр',
'усойқы',
'утамыр',
'утаспа',
'утопия',
'учаске',
'уыздай',
'уыздан',
'уыздат',
'уылдыр',
'уылжыт',
'уыстай',
'уыстас',
'уыстат',
'уыстау',
'уытсыз',
'уыттан',
'уықтас',
'уықтат',
'уықтау',
'уілдес',
'уілдет',
'уілдеу',
'уқалан',
'уқалас',
'уқалат',
'уқалау',
'уәделі',
'уәждес',
'уәждің',
'уәжсіз',
'уәзипа',
'уәлаят',
'фабула',
'фактор',
'фарада',
'фартук',
'фарфор',
'фасоль',
'фашист',
'фаэтон',
'феникс',
'феодал',
'фермер',
'фестон',
'фиалка',
'фигура',
'физика',
'фиксаж',
'филиал',
'филиам',
'финанс',
'финдер',
'флейта',
'флюгер',
'фольга',
'фонарь',
'фонема',
'фонтан',
'форель',
'формат',
'фосфат',
'фосфор',
'фрегат',
'фрезер',
'фреска',
'фундук',
'фургон',
'футбол',
'хайран',
'хайуан',
'халифа',
'халқын',
'хандар',
'хандық',
'ханның',
'ханшыл',
'хариус',
'хартия',
'хатпен',
'хаттал',
'хаттас',
'хаттат',
'хаттау',
'хатына',
'хауана',
'хаулау',
'хахала',
'хикмет',
'хирург',
'хиямәт',
'хлорид',
'хлорла',
'хлорлы',
'хлороз',
'хоккей',
'хошсыз',
'хоштан',
'хоштық',
'хромда',
'хромды',
'цемент',
'цензор',
'цехтық',
'цианды',
'циклон',
'циклоп',
'цимбал',
'цитрус',
'цифрла',
'цифрлы',
'чардаш',
'чекист',
'черкес',
'шабала',
'шабата',
'шабдар',
'шаблон',
'шабушы',
'шабуыл',
'шабылу',
'шабыну',
'шабыра',
'шабырт',
'шабысу',
'шадыра',
'шайдай',
'шайдыр',
'шайлан',
'шайлат',
'шайлау',
'шайлық',
'шайнал',
'шайнам',
'шайнап',
'шайнас',
'шайнат',
'шайнау',
'шайнек',
'шайпау',
'шайтан',
'шайшыл',
'шайылт',
'шайылу',
'шайыну',
'шайысу',
'шайғыз',
'шайқал',
'шайқап',
'шайқас',
'шайқат',
'шайқау',
'шайқақ',
'шайқор',
'шалбар',
'шалдыр',
'шалдық',
'шалжай',
'шалжаю',
'шалжақ',
'шалжию',
'шалкая',
'шалпыл',
'шалпыт',
'шалтай',
'шалшық',
'шалыну',
'шалысу',
'шалығу',
'шалғай',
'шалғам',
'шалғыз',
'шалғын',
'шалғыш',
'шалқай',
'шалқан',
'шалқар',
'шалқаю',
'шалқақ',
'шалқит',
'шалқию',
'шалқып',
'шалқыт',
'шамала',
'шамалы',
'шамасы',
'шамаға',
'шамбыл',
'шамдал',
'шамдан',
'шампан',
'шамшат',
'шамшыл',
'шанала',
'шаналы',
'шананы',
'шанашы',
'шандоз',
'шандыр',
'шандыт',
'шанжау',
'шантаж',
'шаншар',
'шаншыл',
'шапаты',
'шаппай',
'шаптау',
'шаптыр',
'шаптық',
'шапшаң',
'шапшыт',
'шапқыз',
'шапқын',
'шапқыр',
'шапқыт',
'шапқыш',
'шарана',
'шарбақ',
'шардақ',
'шаркей',
'шарлан',
'шарлас',
'шарлат',
'шарлау',
'шарлық',
'шармай',
'шарпыл',
'шарпыс',
'шарпыт',
'шартит',
'шартию',
'шартпа',
'шартта',
'шартты',
'шартыл',
'шартын',
'шартық',
'шартқа',
'шаршап',
'шаршат',
'шаршау',
'шарқат',
'шатасу',
'шатпақ',
'шаттан',
'шаттық',
'шатылт',
'шатылу',
'шатына',
'шатырт',
'шатысу',
'шатқал',
'шаужай',
'шаулат',
'шаулау',
'шаулық',
'шауқан',
'шауқар',
'шауқым',
'шахмат',
'шахтер',
'шашала',
'шашалт',
'шашалу',
'шашбау',
'шашлык',
'шашпай',
'шаштас',
'шаштыр',
'шашылу',
'шашыра',
'шашқыз',
'шаянша',
'шаятын',
'шағала',
'шағалы',
'шағылу',
'шағыну',
'шағысу',
'шақпақ',
'шақпыт',
'шақтал',
'шақтат',
'шақтау',
'шақтыр',
'шақтық',
'шақшақ',
'шақшит',
'шақшию',
'шақырт',
'шақыру',
'шаққыз',
'шаққыш',
'шаңдан',
'шаңдат',
'шаңдау',
'шаңдақ',
'шаңыту',
'шаңғыл',
'шаңғыр',
'шаңқай',
'шаңқан',
'шаңқаю',
'шаңқыл',
'шаһары',
'шебері',
'шевиот',
'шегеле',
'шегере',
'шегерт',
'шегеру',
'шегрен',
'шегіну',
'шедевр',
'шежіре',
'шекара',
'шеккіз',
'шекпен',
'шексіз',
'шектел',
'шектен',
'шектес',
'шектет',
'шектеу',
'шектір',
'шекшек',
'шекшию',
'шекісу',
'шелден',
'шелдеу',
'шелиту',
'шелпек',
'шелтит',
'шелтию',
'шендес',
'шенелу',
'шенесу',
'шербет',
'шерлен',
'шерлеу',
'шермит',
'шермию',
'шертер',
'шертит',
'шертию',
'шертпе',
'шеруле',
'шеткей',
'шетпер',
'шетсіз',
'шетсін',
'шеттен',
'шеттет',
'шеттеу',
'шеттік',
'шетіне',
'шефтік',
'шешеке',
'шешелі',
'шешені',
'шешесі',
'шешкіз',
'шештір',
'шешуге',
'шешуші',
'шешушө',
'шешілу',
'шешімі',
'шешіну',
'шешісу',
'шеңбер',
'шеңгел',
'шибөрі',
'шиедей',
'шиелен',
'шиелеу',
'шиенде',
'шикілі',
'шимайы',
'шинель',
'шинион',
'шипалы',
'ширату',
'ширақы',
'ширегі',
'ширығу',
'шифрла',
'шифрлы',
'шишала',
'шишалы',
'шиырла',
'шиізат',
'шиітте',
'шиітті',
'шкипер',
'шленка',
'шлюпка',
'шнурок',
'шойнақ',
'шойнаң',
'шойқай',
'шойқаң',
'шойқит',
'шойқию',
'шолдыр',
'шолжай',
'шолжақ',
'шолжаң',
'шолжит',
'шолжию',
'шолжың',
'шолпан',
'шолпыл',
'шолтаң',
'шолтит',
'шолтию',
'шолушы',
'шолғыз',
'шолғын',
'шомбал',
'шомдау',
'шомдыр',
'шомылу',
'шомырт',
'шомғыш',
'шонжар',
'шонтай',
'шонтит',
'шонтию',
'шораяқ',
'шорбақ',
'шорлан',
'шорлас',
'шорлау',
'шормақ',
'шортан',
'шоршыт',
'шорқақ',
'шотаяқ',
'шотпен',
'шоттан',
'шоттар',
'шоттың',
'шотына',
'шошайт',
'шошала',
'шоштию',
'шошыну',
'шошыту',
'шоғылу',
'шоқайт',
'шоқиту',
'шоқпар',
'шоқпыт',
'шоқтал',
'шоқтан',
'шоқтық',
'шоқшит',
'шоқшию',
'шоқыла',
'шоқылы',
'шоқыну',
'шоқыту',
'шоңғал',
'шоңқай',
'шоңқал',
'шоңқаю',
'шоңқит',
'шпагат',
'штангы',
'штопор',
'шуақта',
'шуақты',
'шуласу',
'шулату',
'шуылда',
'шыбжың',
'шыжбай',
'шыжбық',
'шыжбың',
'шыжғыр',
'шылану',
'шылбыр',
'шылдыр',
'шылпыл',
'шылпық',
'шылқыт',
'шымайт',
'шымбай',
'шымдал',
'шымдап',
'шымдас',
'шымдау',
'шымдақ',
'шымшым',
'шымшыс',
'шымшыт',
'шымшық',
'шымыра',
'шымқай',
'шымқал',
'шымқан',
'шымқау',
'шынайы',
'шынаяқ',
'шындас',
'шындық',
'шынжау',
'шынжыр',
'шынтақ',
'шыншыл',
'шыныла',
'шынығу',
'шырағы',
'шырдай',
'шырлан',
'шырлат',
'шырмал',
'шырмат',
'шырпыл',
'шырғай',
'шырқал',
'шырқас',
'шырқат',
'шырқау',
'шыттай',
'шыттыр',
'шытулы',
'шытына',
'шытыра',
'шытықы',
'шытқыз',
'шытқыл',
'шығады',
'шығалы',
'шығарт',
'шығару',
'шығары',
'шығасы',
'шығыну',
'шығыны',
'шықтан',
'шықтау',
'шықшию',
'шықшыт',
'шықшөп',
'шыққан',
'шыңайт',
'шыңдал',
'шыңдат',
'шыңдау',
'шыңсыз',
'шыңғыр',
'шыңғыс',
'шыңқыл',
'шікірә',
'шілтен',
'шілтер',
'шірент',
'шіркей',
'шіркеу',
'шіркін',
'шіңгір',
'шіңкіл',
'шүбәлы',
'шүйгін',
'шүйкию',
'шүйлік',
'шүйілт',
'шүйіру',
'шүкейт',
'шүлдік',
'шүлдір',
'шүлжің',
'шүртит',
'шүршит',
'шүршіт',
'шүңгіл',
'шүңейт',
'шүңкит',
'шүңкіл',
'шұбалт',
'шұбалу',
'шұбату',
'шұбырт',
'шұбыру',
'шұлдыр',
'шұлған',
'шұлғау',
'шұлғып',
'шұлғыс',
'шұлғыт',
'шұнайт',
'шұнтит',
'шұнтию',
'шұрқан',
'шұрқыл',
'шұрқыр',
'шұғыла',
'шұқуыш',
'шұқшит',
'шұқшию',
'шұқыла',
'шұқыма',
'шұқыну',
'шұқыту',
'шұңғыл',
'шұңқыр',
'шәксөз',
'шәктен',
'шәкірт',
'шәлдөр',
'шәлкем',
'шәлкес',
'шәлтең',
'шәлтит',
'шәлтік',
'шәрбат',
'шәркей',
'шәртік',
'шәугім',
'шәушию',
'шәуңет',
'шәңгек',
'шәңгір',
'шөбере',
'шөгеру',
'шөжелі',
'шөкеле',
'шөккіз',
'шөктір',
'шөлдет',
'шөлдеу',
'шөлдік',
'шөлейт',
'шөлмек',
'шөмеле',
'шөнжік',
'шөншік',
'шөпшек',
'шөреле',
'шөңгел',
'ыбылжу',
'ыбылжы',
'ыбыржу',
'ыбыржы',
'ыбырсу',
'ыбырсы',
'ыдырас',
'ыдырат',
'ыдырау',
'ызалан',
'ызақор',
'ызылда',
'ызыңда',
'ызыңды',
'ызғыру',
'ылбыра',
'ылдила',
'ылдилы',
'ылжыра',
'ымдасу',
'ымыртт',
'ынатын',
'ынталы',
'ынтығу',
'ырдуан',
'ыржиыс',
'ырылда',
'ырымда',
'ырысты',
'ырықты',
'ырғалт',
'ырғалу',
'ырғасы',
'ырғағы',
'ыспала',
'ыссыла',
'ыссылы',
'ысылда',
'ысылту',
'ысырап',
'ысытпа',
'ысытыл',
'ысытыс',
'ысқаяқ',
'ысқыла',
'ысқырт',
'ысқыру',
'ытырыл',
'ытқыла',
'ытқыту',
'ышқынт',
'ығында',
'ығынды',
'ығырла',
'ығырын',
'ығыста',
'ығытты',
'ықсыру',
'ықтияр',
'ықтият',
'ықтырт',
'ықтыру',
'ықылас',
'ықылым',
'ықылық',
'ықырар',
'ыңылда',
'ыңыран',
'ыңырсу',
'ыңырсы',
'ыңғыру',
'ыңқиту',
'эбонит',
'эврика',
'эгоизм',
'эгоист',
'экзема',
'экипаж',
'элегия',
'электр',
'элерон',
'элизия',
'эллада',
'эллипс',
'эпитет',
'ібіліс',
'ізашар',
'іздену',
'ізетті',
'ізінше',
'ілгері',
'ілеспе',
'ілкіде',
'ілуіне',
'ілігіп',
'ілігіс',
'ілікпе',
'ілімді',
'ілінер',
'ілініп',
'ілініс',
'ілісіп',
'індегі',
'індету',
'інжулі',
'інілік',
'інішек',
'іргелі',
'іректе',
'іркілт',
'ірікте',
'ірілен',
'ірілет',
'ірілік',
'іріткі',
'ірітіл',
'іріңде',
'іріңді',
'ісінде',
'ісінің',
'ісіңкі',
'ішекті',
'ішкері',
'ішімді',
'ішіміз',
'ішінде',
'ішінен',
'іңгәла',
'іңлілі',
'ғайбат',
'ғаклия',
'ғамкүн',
'ғарапа',
'ғаскер',
'ғақлия',
'ғибрат',
'ғиззат',
'ғилман',
'ғылыми',
'ғұлама',
'ғұмыры',
'ғұрлым',
'ғұрпын',
'қабада',
'қабарт',
'қабару',
'қабағы',
'қабынт',
'қабыну',
'қабысу',
'қадала',
'қадалт',
'қадалу',
'қадалы',
'қадану',
'қадари',
'қадары',
'қаделе',
'қажала',
'қажеке',
'қажетт',
'қажеті',
'қазалы',
'қазақи',
'қазақы',
'қаздаң',
'қаздит',
'қаздию',
'қаздыр',
'қазжуа',
'қазиек',
'қазина',
'қазине',
'қазоты',
'қазуат',
'қазулы',
'қазушы',
'қазылы',
'қазына',
'қазғыз',
'қазғыш',
'қайбат',
'қайбір',
'қайдам',
'қайдан',
'қайзал',
'қайзаң',
'қаймал',
'қаймақ',
'қаймию',
'қаймық',
'қайнай',
'қайнап',
'қайнар',
'қайнас',
'қайнат',
'қайнау',
'қайнаң',
'қайпаң',
'қайрал',
'қайран',
'қайрас',
'қайрат',
'қайрақ',
'қайраң',
'қайсар',
'қайтар',
'қайтсе',
'қайтта',
'қайтыл',
'қайтым',
'қайтып',
'қайтыс',
'қайыра',
'қайырт',
'қайыру',
'қайысу',
'қайыту',
'қайғыр',
'қайқай',
'қайқаю',
'қайқақ',
'қайқаң',
'қайқит',
'қайқию',
'қалады',
'қалайы',
'қалалы',
'қаласы',
'қалауы',
'қалашы',
'қалбай',
'қалбақ',
'қалбаң',
'қалбит',
'қалбыр',
'қалдыр',
'қалдық',
'қалжау',
'қалжақ',
'қалжың',
'қалмай',
'қалмау',
'қалмақ',
'қалпақ',
'қалтар',
'қалтақ',
'қалтаң',
'қалтит',
'қалтию',
'қалтыр',
'қалтқы',
'қалуда',
'қалуен',
'қалшит',
'қалшию',
'қалшыл',
'қалған',
'қалғыз',
'қалғып',
'қалғыс',
'қалғыт',
'қалқай',
'қалқам',
'қалқан',
'қалқаш',
'қалқаю',
'қалқит',
'қалқию',
'қалқыс',
'қалқыт',
'қамала',
'қамалт',
'қамалу',
'қамарт',
'қамбар',
'қамбоз',
'қамдал',
'қамдан',
'қамдап',
'қамдас',
'қамдат',
'қамдау',
'қамдық',
'қамзау',
'қамзол',
'қампай',
'қампит',
'қамсау',
'қамсақ',
'қамсыз',
'қамтуы',
'қамтыл',
'қамтып',
'қамтыт',
'қамшат',
'қамшау',
'қамшыл',
'қамығу',
'қамқап',
'қамқор',
'қанату',
'қандай',
'қандал',
'қандан',
'қандас',
'қандат',
'қандау',
'қандақ',
'қанден',
'қандым',
'қандыр',
'қанеки',
'қанжар',
'қаннен',
'қантты',
'қаншие',
'қаншыл',
'қаншыр',
'қаншық',
'қанығу',
'қанғыз',
'қанқор',
'қанқыз',
'қапала',
'қапалы',
'қапсыз',
'қапсыр',
'қаптал',
'қаптас',
'қаптат',
'қаптау',
'қаптыр',
'қапшық',
'қапыда',
'қапылт',
'қапылу',
'қапысы',
'қапқыш',
'қарады',
'қарайт',
'қарала',
'қаралу',
'қаралы',
'қарама',
'қарамы',
'қарану',
'қараот',
'қараса',
'қарасу',
'қарату',
'қарауы',
'қараша',
'қарашы',
'қарақу',
'қарбан',
'қарбаң',
'қарбыз',
'қарбыт',
'қарбық',
'қардай',
'қардар',
'қаржал',
'қаржас',
'қаржау',
'қарлан',
'қарлат',
'қарлау',
'қарлық',
'қарлұқ',
'қармал',
'қарман',
'қармас',
'қармат',
'қармау',
'қармақ',
'қарның',
'қарпыл',
'қарпыс',
'қарпыт',
'қарсал',
'қарсақ',
'қарсаң',
'қарсыз',
'қартай',
'қартаю',
'қартаң',
'қартша',
'қарулы',
'қаршай',
'қаршын',
'қарызы',
'қарылт',
'қарылу',
'қарыма',
'қарысу',
'қарығу',
'қарғал',
'қарғам',
'қарған',
'қарғап',
'қарғас',
'қарғат',
'қарғау',
'қарғаш',
'қарғын',
'қарғыс',
'қарғыт',
'қарқыл',
'қарқын',
'қасаба',
'қасарт',
'қасару',
'қасақы',
'қасида',
'қасиет',
'қаскөй',
'қаспақ',
'қастан',
'қастас',
'қастер',
'қастық',
'қасына',
'қасынт',
'қасыну',
'қасқай',
'қасқан',
'қасқар',
'қасқаю',
'қасқит',
'қасқию',
'қасқыр',
'қатайт',
'қатала',
'қатарт',
'қатару',
'қатерл',
'қатнас',
'қатпар',
'қаттал',
'қаттам',
'қаттан',
'қаттас',
'қаттат',
'қаттау',
'қаттыр',
'қатулы',
'қатына',
'қатыны',
'қатырт',
'қатыру',
'қатыса',
'қатысу',
'қатысы',
'қатқан',
'қатқақ',
'қатқыз',
'қатқыл',
'қауаша',
'қаудан',
'қаудит',
'қаудию',
'қаудыр',
'қаужал',
'қаужан',
'қаужас',
'қаужат',
'қаужау',
'қаужаң',
'қаужит',
'қаужию',
'қаузал',
'қаузан',
'қаузас',
'қаузат',
'қаузау',
'қаулас',
'қаулат',
'қаулау',
'қаумет',
'қаупер',
'қаупит',
'қаупию',
'қаусат',
'қаусау',
'қаусыр',
'қауырт',
'қауысу',
'қауышу',
'қауқай',
'қауқар',
'қауқаю',
'қауқит',
'қауқию',
'қаштыр',
'қашушы',
'қашыру',
'қашқан',
'қашқар',
'қашқақ',
'қашқыз',
'қашқын',
'қаюлау',
'қаярсу',
'қаярсы',
'қаяула',
'қаяулы',
'қаяққа',
'қағажу',
'қағида',
'қағлез',
'қағушы',
'қағына',
'қағынт',
'қағыну',
'қағысу',
'қағыту',
'қақайт',
'қақала',
'қақалт',
'қақалу',
'қақбас',
'қақпай',
'қақпан',
'қақпас',
'қақпақ',
'қақпыш',
'қақсал',
'қақсат',
'қақсау',
'қақсыт',
'қақтал',
'қақтан',
'қақтат',
'қақтау',
'қақтыр',
'қақтық',
'қақшай',
'қақшақ',
'қақшаң',
'қақшит',
'қақшыт',
'қақылы',
'қақыра',
'қақырт',
'қақыру',
'қаққан',
'қаққыз',
'қаққыш',
'қаңбақ',
'қаңсыт',
'қаңсық',
'қаңтар',
'қаңыра',
'қаңғыл',
'қаңғыр',
'қаңғыт',
'қаңқай',
'қаңқаю',
'қаңқақ',
'қаңқит',
'қаңқию',
'қаңқыл',
'қидала',
'қизала',
'қимайы',
'қималы',
'қинала',
'қиналт',
'қиналу',
'қирату',
'қисайт',
'қиттай',
'қиттас',
'қиттат',
'қиттау',
'қитула',
'қитығу',
'қиылыс',
'қиында',
'қиынға',
'қиықта',
'қиықты',
'қиықша',
'қиғыла',
'қиқайт',
'қиқала',
'қиқиып',
'қиқула',
'қобыла',
'қобылы',
'қобыра',
'қодайт',
'қодыға',
'қожасу',
'қожасы',
'қожбан',
'қожыра',
'қозалы',
'қоздан',
'қоздап',
'қоздат',
'қоздау',
'қоздыр',
'қозыла',
'қозылы',
'қозышы',
'қозыға',
'қозғал',
'қозғас',
'қозғат',
'қозғау',
'қозғақ',
'қозғыш',
'қойдыр',
'қойдың',
'қойлар',
'қойлау',
'қойлық',
'қойнау',
'қойсам',
'қойтта',
'қойылт',
'қойылу',
'қойырт',
'қойыру',
'қойысу',
'қойған',
'қойғыз',
'қойқап',
'қойқақ',
'қойқаң',
'қолала',
'қолаға',
'қолақы',
'қолбаң',
'қолдан',
'қолдар',
'қолдас',
'қолдау',
'қолдың',
'қолмен',
'қолпаш',
'қолтық',
'қолхат',
'қолхоз',
'қолына',
'қолыңа',
'қолғап',
'қолқою',
'қомдал',
'қомдан',
'қомдас',
'қомдат',
'қомдау',
'қомдық',
'қомпай',
'қомпаю',
'қомпаң',
'қомпит',
'қомпию',
'қомсын',
'қонаға',
'қондыр',
'қонжит',
'қонжию',
'қонжық',
'қонғыз',
'қопалы',
'қопарт',
'қопару',
'қопсыт',
'қоптыр',
'қораба',
'қорада',
'қорала',
'қоралы',
'қорама',
'қорашы',
'қорбаң',
'қорбит',
'қорбию',
'қордап',
'қордию',
'қордың',
'қоржаң',
'қоржию',
'қоржын',
'қорлан',
'қорлат',
'қорлау',
'қорлық',
'қорсыл',
'қорсын',
'қорсыт',
'қортаң',
'қортию',
'қортпа',
'қортық',
'қорушы',
'қоруыш',
'қоршай',
'қоршал',
'қоршас',
'қоршат',
'қоршау',
'қорына',
'қорыту',
'қорғай',
'қорғал',
'қорған',
'қорғап',
'қорғас',
'қорғат',
'қорғау',
'қорқау',
'қорқақ',
'қорқор',
'қорқыл',
'қорқыс',
'қорқыт',
'қосаяқ',
'қоспақ',
'қостал',
'қостан',
'қостас',
'қостат',
'қостау',
'қостыр',
'қосыла',
'қосылу',
'қосқан',
'қосқыз',
'қосқыш',
'қотарт',
'қотару',
'қоттау',
'қоштал',
'қоштас',
'қоштат',
'қоштау',
'қоштық',
'қошуақ',
'қошқар',
'қошқыл',
'қоюлан',
'қоюлат',
'қоюлау',
'қоюлық',
'қояйын',
'қоялат',
'қоялау',
'қоянды',
'қояным',
'қоярда',
'қоясың',
'қоғалы',
'қоғами',
'қоғамы',
'қоқайт',
'қоқсыт',
'қоқсық',
'қоқтал',
'қоқтық',
'қоқшию',
'қоқыра',
'қоңайт',
'қоңдан',
'қоңдық',
'қоңсыз',
'қоңсық',
'қоңқай',
'қоңқаш',
'қоңқаю',
'қоңқақ',
'қоңқит',
'қоңқию',
'қоңқыл',
'қоңқыш',
'қуалат',
'қуалау',
'қуаныс',
'қуаныш',
'қуатта',
'қуатты',
'қуаңда',
'қуаңқы',
'қудала',
'қудыра',
'қудырт',
'қузала',
'қулану',
'қунақы',
'қусыну',
'қусырт',
'қусыру',
'қутаяқ',
'қуыным',
'қуырма',
'қуырыл',
'қуыста',
'қыдыра',
'қыдырт',
'қыжыра',
'қыжырт',
'қызарт',
'қыздай',
'қыздар',
'қыздыр',
'қыздық',
'қызмет',
'қызшыл',
'қызына',
'қызығу',
'қызығы',
'қызған',
'қызғыл',
'қызғын',
'қызғыш',
'қылады',
'қылдай',
'қылжақ',
'қылжаң',
'қылжыр',
'қылмаң',
'қылмит',
'қылмыс',
'қылмың',
'қылпыт',
'қылпық',
'қылтан',
'қылтит',
'қылтық',
'қылтың',
'қылует',
'қылшан',
'қылшыл',
'қылшық',
'қылығы',
'қылған',
'қылғын',
'қылғыт',
'қылқан',
'қылқит',
'қылқың',
'қымбат',
'қымсын',
'қымтал',
'қымтан',
'қымтап',
'қымтас',
'қымтат',
'қымтау',
'қымтыл',
'қымтыр',
'қымқап',
'қымқыр',
'қыналы',
'қынама',
'қындай',
'қындық',
'қынжыл',
'қырағы',
'қырбай',
'қырбат',
'қырбақ',
'қырбық',
'қырдан',
'қыржит',
'қыржық',
'қыржың',
'қырлан',
'қырлас',
'қырлат',
'қырлау',
'қырлық',
'қырман',
'қырнал',
'қырнас',
'қырнат',
'қырнау',
'қырпық',
'қырсау',
'қырсыз',
'қырсық',
'қыртит',
'қыртию',
'қыртыл',
'қыртыс',
'қыруар',
'қырушы',
'қыршыл',
'қыршын',
'қыршыс',
'қыршыт',
'қырылу',
'қырыну',
'қырысу',
'қырғыз',
'қырғын',
'қырғыш',
'қырқай',
'қырқар',
'қырқыл',
'қырқым',
'қырқын',
'қырқыс',
'қырқыт',
'қысасу',
'қысаға',
'қыспақ',
'қыстай',
'қыстал',
'қыстас',
'қыстат',
'қыстау',
'қыстақ',
'қыстыр',
'қыстық',
'қысыла',
'қысылт',
'қысылу',
'қысыра',
'қысысу',
'қысқар',
'қысқаш',
'қысқыш',
'қытайы',
'қыттас',
'қыттау',
'қышкер',
'қышлақ',
'қыштас',
'қыштат',
'қыштау',
'қышылу',
'қышыма',
'қышыну',
'қышысу',
'қышқаш',
'қышқыл',
'қышқыр',
'қыңсыл',
'қыңқыл',
'қүтпан',
'қүтқан',
'құбаша',
'құбыла',
'құбылт',
'құбылу',
'құбыры',
'құдайы',
'құдасу',
'құдасы',
'құдаша',
'құдағи',
'құжаты',
'құжбан',
'құжына',
'құжыра',
'құзғын',
'құйдыр',
'құймақ',
'құйрық',
'құйтию',
'құйтың',
'құйылу',
'құйған',
'құйғыз',
'құйғын',
'құйғыт',
'құйғыш',
'құлазу',
'құлазы',
'құлалы',
'құлату',
'құлбаң',
'құлдан',
'құлдат',
'құлдау',
'құлдыр',
'құлдық',
'құлмақ',
'құлпыр',
'құлтақ',
'құлтаң',
'құлшын',
'құлқын',
'құмайт',
'құмарт',
'құмдау',
'құмдақ',
'құмсау',
'құмшық',
'құмыра',
'құмыты',
'құмығу',
'құмған',
'құнарт',
'құнару',
'құндас',
'құндақ',
'құндыз',
'құндық',
'құнжит',
'құнжию',
'құнжың',
'құнсыз',
'құнтақ',
'құнтаң',
'құнтит',
'құнтию',
'құнтта',
'құнтты',
'құнына',
'құнысу',
'құнығу',
'құптал',
'құптан',
'құптас',
'құптат',
'құптау',
'құрады',
'құрайт',
'құралы',
'құрама',
'құрамы',
'құрауы',
'құрбан',
'құрбаң',
'құрдай',
'құрдан',
'құрдас',
'құрдым',
'құрдыр',
'құржаң',
'құржию',
'құржың',
'құрлау',
'құрлық',
'құрман',
'құрмет',
'құрсал',
'құрсан',
'құрсат',
'құрсау',
'құрсын',
'құртаң',
'құртпа',
'құртта',
'құртыл',
'құртыс',
'құртқа',
'құруда',
'құруды',
'құрула',
'құрулы',
'құрыса',
'құрысу',
'құрған',
'құрғат',
'құрғау',
'құрғақ',
'құрғыз',
'құрғыр',
'құсалы',
'құстай',
'құстыр',
'құсқыз',
'құтайт',
'құтсыз',
'құтылт',
'құтылу',
'құтырт',
'құтыру',
'құтқан',
'құтқар',
'құшнаш',
'құштар',
'құштыр',
'құқылы',
'құқығы',
'үбірлі',
'үгінді',
'үгітте',
'үгітші',
'үдерім',
'үдетіл',
'үдірей',
'үдірею',
'үзбеле',
'үзбелі',
'үзеген',
'үзеңгі',
'үзілді',
'үзілту',
'үзіліп',
'үзіліс',
'үзінді',
'үйдегі',
'үйезде',
'үйелет',
'үйеңкі',
'үйкесу',
'үйлену',
'үйлерд',
'үйлесу',
'үймеле',
'үйрену',
'үйрету',
'үйткіз',
'үйттір',
'үйтілу',
'үйтісу',
'үйіліп',
'үйіліс',
'үйінде',
'үйінді',
'үйірле',
'үйірлі',
'үйірме',
'үйіріл',
'үйірім',
'үйіріс',
'үктіру',
'үкідей',
'үкікөз',
'үкілен',
'үкілет',
'үкімет',
'үлбіре',
'үлгерт',
'үлгеру',
'үлгіде',
'үлгіле',
'үлгілі',
'үлгірт',
'үлгісі',
'үлгіші',
'үлдіре',
'үлекті',
'үлесті',
'үлесші',
'үлесіл',
'үлеуіш',
'үлкейт',
'үмелет',
'үмелеу',
'үмітті',
'үналғы',
'үндеме',
'үндесу',
'үнемде',
'үнемді',
'үпелек',
'үпілде',
'үргізу',
'үргіле',
'үрдірт',
'үрдіру',
'үрдісі',
'үреген',
'үрейлі',
'үркіту',
'үрлесу',
'үрлету',
'үрмеле',
'үрмелі',
'үрпиту',
'үрпиіс',
'үрінді',
'үріпті',
'үскене',
'үскіле',
'үскіру',
'үстегі',
'үстелу',
'үстеме',
'үстету',
'үстілі',
'үстіне',
'үстірт',
'үсігіш',
'үсініп',
'үтікте',
'үтірлі',
'үшегіз',
'үшеуле',
'үшкірт',
'үшкіру',
'үшмүше',
'үшінші',
'үшүйлі',
'үңгуіш',
'үңгіле',
'үңгіме',
'үңіліп',
'үңіліс',
'үңірей',
'үңірек',
'үңірею',
'ұбырып',
'ұдайым',
'ұжымды',
'ұзарту',
'ұзатыл',
'ұзақта',
'ұзақты',
'ұзынды',
'ұзынша',
'ұйлығу',
'ұйпала',
'ұйтқыт',
'ұйымда',
'ұйымға',
'ұйынды',
'ұйытыл',
'ұйытқы',
'ұйықта',
'ұйғару',
'ұйқасу',
'ұйқылы',
'ұлтарт',
'ұлтару',
'ұлттык',
'ұлттық',
'ұлттың',
'ұлтшыл',
'ұлутас',
'ұлылық',
'ұлықсу',
'ұлықсы',
'ұлықта',
'ұлғайт',
'ұмсынт',
'ұмсыну',
'ұмтылт',
'ұмтылу',
'ұмытыл',
'ұнамды',
'ұнасым',
'ұнатыс',
'ұралат',
'ұралау',
'ұранда',
'ұраншы',
'ұрдыма',
'ұрттам',
'ұрттас',
'ұрттат',
'ұрттау',
'ұртына',
'ұртқал',
'ұрудан',
'ұрысыс',
'ұрғашы',
'ұрғыла',
'ұсатыл',
'ұсақта',
'ұсақты',
'ұстама',
'ұстана',
'ұстану',
'ұстара',
'ұстарт',
'ұстасу',
'ұстасы',
'ұсынды',
'ұсынуы',
'ұсыныл',
'ұсыным',
'ұсыныс',
'ұтудың',
'ұтылыс',
'ұтымды',
'ұтырлы',
'ұтысты',
'ұштасу',
'ұшырас',
'ұшырат',
'ұшырау',
'ұшырлы',
'ұшырыл',
'ұшырыс',
'ұшықта',
'ұшқары',
'ұшқасу',
'ұялмай',
'ұялшақ',
'ұятсыз',
'ұятсын',
'ұяттық',
'ұяңдан',
'ұяңдат',
'ұяңдау',
'ұяңдық',
'ұғымда',
'ұғымды',
'ұғыныл',
'ұғынып',
'ұғыныс',
'ұқпаит',
'ұқсата',
'ұқтырт',
'ұқыпта',
'ұқыпты',
'ұңғуыр',
'ұңғыла',
'ұңғыма',
'һәйран',
'әбдіре',
'әбігер',
'әбілет',
'әбүиір',
'әдепкі',
'әдепті',
'әдетке',
'әдетте',
'әділет',
'әдіпте',
'әдіпті',
'әжелік',
'әжесін',
'әжетай',
'әжуала',
'әжімді',
'әжірек',
'әзелде',
'әзілде',
'әзірге',
'әзіргі',
'әзірде',
'әзірет',
'әзірле',
'әзірше',
'әзәзіл',
'әйгіле',
'әйгілі',
'әйтеке',
'әкежан',
'әкелді',
'әкелер',
'әкелсе',
'әкелік',
'әкелім',
'әкелін',
'әкеліп',
'әкесіз',
'әкесін',
'әкетай',
'әкетіл',
'әкімсі',
'әкімші',
'әкірең',
'әлгіде',
'әлгіше',
'әлдене',
'әлдиле',
'әлекей',
'әлемге',
'әлемде',
'әлеміш',
'әлеует',
'әлжуаз',
'әлсіре',
'әлінше',
'әліпби',
'әліппе',
'әлөкпе',
'әманда',
'әмірлі',
'әмірші',
'әндеме',
'әндері',
'әндету',
'әнеугі',
'әнжіме',
'әнұран',
'әпенді',
'әптиек',
'әредік',
'әрекет',
'әрлену',
'әрнеме',
'әсемде',
'әсемсі',
'әсерлі',
'әсерін',
'әскери',
'әуежай',
'әуезді',
'әуезов',
'әуелгі',
'әуелде',
'әуелен',
'әуелет',
'әуелеу',
'әуенді',
'әукелі',
'әулекө',
'әулігу',
'әуреле',
'әурелі',
'әуселе',
'әфсана',
'әшекей',
'әшкере',
'әңгіме',
'өгейсі',
'өгізді',
'өгізше',
'өжелен',
'өжетсу',
'өжетсі',
'өзгеге',
'өзгеде',
'өзгерд',
'өзгерт',
'өзгеру',
'өзгесі',
'өзгеше',
'өздері',
'өзекте',
'өзекті',
'өзекше',
'өзелен',
'өзенше',
'өзеуре',
'өзімен',
'өзімсу',
'өзімсі',
'өзінде',
'өзінен',
'өзінің',
'өзіңді',
'өйткіз',
'өкпеле',
'өкпелі',
'өкшеле',
'өкілге',
'өкілсі',
'өкілін',
'өкімде',
'өкімді',
'өкімін',
'өкініс',
'өкініш',
'өкірек',
'өкірік',
'өлемін',
'өлерше',
'өлеңге',
'өлеңді',
'өлеңші',
'өлкелі',
'өлмелі',
'өлмеші',
'өлтірт',
'өлтіру',
'өлтірі',
'өлшемі',
'өлімді',
'өмірде',
'өмірді',
'өмірем',
'өмірлі',
'өндірт',
'өндіру',
'өнерге',
'өнерді',
'өнерле',
'өнерлі',
'өнерін',
'өпірем',
'өрбиді',
'өрбіте',
'өрекпу',
'өрекпі',
'өрелен',
'өрелес',
'өрелеу',
'өрелік',
'өресіз',
'өрлену',
'өрмеле',
'өрсіну',
'өрттей',
'өршігу',
'өрілуі',
'өрімді',
'өрімші',
'өрісті',
'өрісін',
'өрісің',
'өсекші',
'өспелі',
'өсімді',
'өсіріл',
'өсіңкі',
'өтеген',
'өтелік',
'өтесін',
'өтетін',
'өтеуге',
'өтеуде',
'өтеуді',
'өткеру',
'өткізу',
'өтпелі',
'өттасы',
'өтімді',
'өтінім',
'өтініш',
'өтірік',
'өтөрөк',
'өштесу',
'өшіріс',
'өңгерт',
'өңгеру',
'өңдері',
'өңдесу',
'өңезді',
'өңеште',
'өңірею',
'өңірле',
'өңірлі',
'Абылай',
'Азамат',
'Айбала',
'Айбике',
'Айгуль',
'Айдана',
'Айзада',
'Айпара',
'Айсулу',
'Ақлима',
'Ақшора',
'Алдияр',
'Альфия',
'Атабай',
'Атабек',
'Багила',
'Байсал',
'Балжан',
'Барлас',
'Баттал',
'Баязит',
'Бекзат',
'Биржан',
'Буркан',
'Ғазиза',
'Ғаухар',
'Ғафура',
'Гүлназ',
'Гүлшат',
'Дамеля',
'Данара',
'Дандай',
'Даниал',
'Данияр',
'Дариға',
'Дармен',
'Дилара',
'Динара',
'Ерасыл',
'Ескали',
'Жазира',
'Жамбыл',
'Жамига',
'Жамшид',
'Жангир',
'Жандос',
'Жансая',
'Жолгай',
'Жолдас',
'Жулдыз',
'Зарина',
'Зауреш',
'Зейнеп',
'Зиягул',
'Зулфия',
'Зумрат',
'Зылика',
'Қайрат',
'Қайсар',
'Калима',
'Қамбар',
'Канипа',
'Кармыс',
'Кобжан',
'Қуаныш',
'Магзум',
'Мадина',
'Мақсат',
'Малика',
'Мансур',
'Мардан',
'Матiби',
'Маулен',
'Машрап',
'Мейрам',
'Мухтар',
'Ныгмет',
'Рабиға',
'Ракима',
'Раушан',
'Рахила',
'Рахман',
'Рустем',
'Рустэм',
'Сабира',
'Санжар',
'Саулет',
'Саурык',
'Сафуан',
'Сералi',
'Султан',
'Сырбай',
'Таймас',
'Такауи',
'Талмас',
'Таргын',
'Тауман',
'Тауфик',
'Телжан',
'Тогжан',
'Торгын',
'Уазипа',
'Уасила',
'Фариза',
'Фатима',
'Фируза',
'Шамсия',
'Шарипа',
'Шерхан',
'Шолпан',
'Ыбырай',
'Ыдырас',
]