export const WORDS = [
'сазгер',
'жолдас',
'жаулық',
'желкен',
'тамсан',
'балдыз',
'үкімет',
'микроб',
'азғыру',
'салқын',
'талшық',
'мезгіл',
'жұрнақ',
'жималы',
'құрбан',
'дүбәра',
'құлшын',
'жәндік',
'балгер',
'ұрғашы',
'тағдыр',
'жолаяқ',
'сөздік',
'қамзол',
'мөлдір',
'отбасы',
'долана',
'шәугім',
'дәулет',
'қырман',
'тоңғақ',
'төреші',
'баялыш',
'тәмсіл',
'алмұрт',
'ұтылыс',
'бойшаң',
'сырнай',
'торсық',
'ишарат',
'мыстан',
'түшкір',
'секпіл',
'жұпыны',
'әбігер',
'алапес',
'тойпаң',
'сүлдер',
'шабуыл',
'туралы',
'бөртпе',
'мешкей',
'сақина',
'сымбат',
'жарқыл',
'жорушы',
'ақырын',
'жалбыз',
'мәшһүр',
'тұрғын',
'қолхат',
'жемтік',
'комета',
'антқор',
'дербес',
'бетпақ',
'жәрдем',
'атбегі',
'шынтақ',
'сотқар',
'кейбір',
'ойсыра',
'жарқын',
'күніге',
'бойдақ',
'жылдам',
'мінбер',
'нәпақа',
'сүндет',
'тандыр',
'қыстау',
'жемқор',
'өзекті',
'тағзым',
'заңгер',
'көпшік',
'теңдік',
'жылпос',
'диплом',
'құмыра',
'ашымық',
'опасыз',
'жазира',
'тәртіп',
'уатқыш',
'қойнау',
'жалғыз',
'қошқар',
'бұзақы',
'қалбыр',
'дәурен',
'қызмет',
'қоржын',
'жайдақ',
'нәтиже',
'жүгері',
'жөндеу',
'болжам',
'сатқын',
'үдеріс',
'жетісу',
'даңғыл',
'кемтар',
'хикмет',
'бейпіл',
'бәлкім',
'мұңдас',
'құлқын',
'кәнігі',
'әңгіме',
'теңбіл',
'ілгері',
'саяжай',
'термин',
'жомарт',
'тұрмыс',
'шексіз',
'жыңғыл',
'боздақ',
'оттегі',
'объект',
'түйнек',
'күкірт',
'мүшкіл',
'күндіз',
'төркін',
'қарғыс',
'бармақ',
'өсекші',
'қалдық',
'шіркеу',
'тұқиян',
'тоқсан',
'құмған',
'поэзия',
'жәутік',
'гүлзар',
'моншақ',
'рұқсат',
'серпін',
'бүктеу',
'бүйрек',
'тропик',
'сырбаз',
'тізбек',
'көлбеу',
'долбар',
'мойнақ',
'болмыс',
'мұзарт',
'албырт',
'сорақы',
'зұлмат',
'құрсау',
'қасиет',
'шәкірт',
'тәрбие',
'үйеңкі',
'жоғары',
'сортаң',
'жұмбақ',
'қымбат',
'омырау',
'сөйлем',
'сәнқой',
'түндік',
'дәлдік',
'дегбір',
'түпсіз',
'шандыр',
'лакмус',
'желаяқ',
'дәнеңе',
'шілтер',
'нанғыш',
'құрмет',
'төскей',
'мұрсат',
'толқын',
'ойнақы',
'тұлпар',
'тірлік',
'тотығу',
'меншік',
'құдайы',
'қартаң',
'ықтияр',
'шибөрі',
'мансұқ',
'дәптер',
'ағайын',
'тұмсық',
'мұқият',
'наубай',
'абырой',
'тасқын',
'зейнет',
'сылтау',
'бөрене',
'бұйрық',
'шекара',
'жетпіс',
'дәстүр',
'циклоп',
'жайлық',
'мұқтаж',
'қарбыз',
'қайнар',
'валюта',
'жұлдыз',
'күлгін',
'көркем',
'тапқыр',
'жиырма',
'әшейін',
'жайлау',
'діңгек',
'сусиыр',
'міндет',
'наразы',
'өкілет',
'батпан',
'тұңғыш',
'ақырет',
'мақпал',
'жазмыш',
'сұхбат',
'қыруар',
'сырқат',
'ортеке',
'тәулік',
'әредік',
'бөдене',
'бапкер',
'жеңгей',
'тәлпіш',
'бейнет',
'қауырт',
'қайрат',
'келсап',
'одағай',
'белуар',
'белдеу',
'шолпан',
'дулыға',
'саумал',
'сандық',
'шүйгін',
'дүмпіл',
'ғибрат',
'қылмыс',
'құндыз',
'нәубет',
'масаты',
'кемпір',
'жалақы',
'сатпақ',
'күмбір',
'майлық',
'дәрмен',
'призма',
'әуежай',
'жөндік',
'малғұн',
'даңғой',
'бизнес',
'үйірме',
'мейрам',
'делдал',
'қорған',
'тұспал',
'зәйтүн',
'түкпір',
'башпай',
'шалқан',
'селсоқ',
'зиярат',
'бұлшық',
'шортан',
'жүйрік',
'сұлтан',
'жайсаң',
'қамқор',
'бұқара',
'шүлдір',
'тірсек',
'жағдай',
'бәсеке',
'әскери',
'мерзім',
'оқулық',
'жөргек',
'сәулет',
'шұғыла',
'шатпақ',
'зардап',
'балғын',
'наурыз',
'абысын',
'бейқам',
'тармақ',
'бейбақ',
'әшекей',
'мылқау',
'жоқтау',
'жоспар',
'мәдени',
'бітпес',
'шоқпар',
'едәуір',
'бүркіт',
'лақтыр',
'сүмбіл',
'үзеңгі',
'физика',
'күмбез',
'шалғам',
'бұршақ',
'миқұрт',
'шұңқыр',
'әліпби',
'раушан',
'тылсым',
'жаңбыр',
'дұшпан',
'қаскөй',
'үзінді',
'ертегі',
'босқын',
'жаялық',
'түйсік',
'септік',
'белдік',
'даукес',
'рақмет',
'құрдас',
'сұмдық',
'шіркей',
'мұндай',
'толғақ',
'борсық',
'магнит',
'жолдау',
'бейуақ',
'бастық',
'тырнақ',
'тышқан',
'ғұлама',
'қуаныш',
'сырмақ',
'қиянат',
'дөрекі',
'мекиен',
'тілмаш',
'жартас',
]